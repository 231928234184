import React from "react";
import { Form, Row, Col } from "react-bootstrap";

interface Props {
    label: string;
    value: string | undefined;
    disabled?: boolean;
    onChange?: (value: string) => void;
};

class EditableField extends React.Component<Props> {
    render() {
        return (
            <Form.Group as={Row}>
                <Form.Label column sm={3} style={{fontWeight: 'bold'}}>
                    {this.props.label}
                </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        type="text"
                        value={this.props.value}
                        disabled={this.props.disabled}
                        onChange={(event) => {
                            if (this.props.onChange != undefined)
                                this.props.onChange(event.target.value)
                        }}
                    />
                </Col>
            </Form.Group>
        );
    }
}

export default EditableField;