import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Driver, pool, race, racePrediction } from "../Models";
import Select, { GroupTypeBase, OptionTypeBase, Styles } from "react-select";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import styled from "styled-components";
import { NavBar } from "../Components/NavBar";
import { Service } from "../Service";
import CountdownPill from "../Components/CountdownPill";
import { PageLoadingPlaceholder } from "../Components/PageLoadingPlaceholder";
import { Constants } from "../Constants";

enum FormControl {
  pos1 = "racePrediction.Pos1",
  pos2 = "racePrediction.Pos2",
  pos3 = "racePrediction.Pos3",
  pos4 = "racePrediction.Pos4",
  pos5 = "racePrediction.Pos5",
  pos6 = "racePrediction.Pos6",
  pos7 = "racePrediction.Pos7",
  pos8 = "racePrediction.Pos8",
  pos9 = "racePrediction.Pos9",
  pos10 = "racePrediction.Pos10",
  fastest = "racePrediction.Fastest",
}

enum Mode {
  Create,
  Edit,
}

enum PageState {
  Loading,
  NotFound,
  Done,
}

export const RacePredictionPage = ({ match }: any) => {
  const {
    params: { poolSlug, round },
  } = match;

  const [mode, setMode] = useState<Mode>();
  const [pageState, setPageState] = useState<PageState>(PageState.Loading);

  const [pool, setPool] = useState<pool | null>(null);
  const [raceInfo, setRaceInfo] = useState<race>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [racePrediction, setRacePrediction] = useState<racePrediction>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showStartingGrid, setShowStartingGrid] = useState<boolean>(false);

  useEffect(() => {
    Service.getPool(poolSlug).then((response: Response) => {
      if (response.ok) {
        response.json().then((poolResult: pool) => {
          setPool(poolResult);
        });
      }
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (pool !== null) {
      Service.getDrivers(pool.divisionSlug).then((response: Response) => {
        if (response.ok) {
          response.json().then((driversResult: Driver[]) => {
            setDrivers(driversResult);
          });
        }
      });
      Service.fetchRace(pool.divisionSlug, round).then((response: Response) => {
        if (response.status === 200) {
          const responseBody = response.json();
          responseBody.then((info: race) => {
            setRaceInfo(info);
          });
        } else if (response.status === 404) {
          setPageState(PageState.NotFound);
        }
      });
    }
    return () => {};
  }, [pool]);

  useEffect(() => {
    if (raceInfo !== undefined) {
      Service.fetchRacePrediction(poolSlug, round).then(
        (response: Response) => {
          if (response.ok) {
            response.json().then((prediction: racePrediction) => {
              setMode(Mode.Edit);
              setRacePrediction(prediction);
            });
          } else if (response.status === 404) {
            setMode(Mode.Create);
            setRacePrediction({ round: round } as racePrediction);
          }
          setPageState(PageState.Done);
        }
      );
    }
    return () => {};
  }, [raceInfo]);

  const onDriverSelected = (id: string, selectedDriver: number | null) => {
    let prediction = racePrediction;

    if (prediction) {
      switch (id) {
        case FormControl.pos1:
          prediction.pos1 = selectedDriver;
          break;
        case FormControl.pos2:
          prediction.pos2 = selectedDriver;
          break;
        case FormControl.pos3:
          prediction.pos3 = selectedDriver;
          break;
        case FormControl.pos4:
          prediction.pos4 = selectedDriver;
          break;
        case FormControl.pos5:
          prediction.pos5 = selectedDriver;
          break;
        case FormControl.pos6:
          prediction.pos6 = selectedDriver;
          break;
        case FormControl.pos7:
          prediction.pos7 = selectedDriver;
          break;
        case FormControl.pos8:
          prediction.pos8 = selectedDriver;
          break;
        case FormControl.pos9:
          prediction.pos9 = selectedDriver;
          break;
        case FormControl.pos10:
          prediction.pos10 = selectedDriver;
          break;
        case FormControl.fastest:
          prediction.fastestLap = selectedDriver;
          break;
      }
      setRacePrediction(prediction);
    }
  };

  const DriverSelector = (props: {
    label: string;
    value: number | null;
    startingGridDriver: number | null | undefined;
    onDriverChange: (id: string, selectedDriver: number | null) => void;
    id: FormControl;
  }) => {
    const onChangeHandler = (selectedOption: OptionTypeBase | null) => {
      props.onDriverChange(props.id, selectedOption?.value ?? null);
    };

    const options: OptionTypeBase[] = drivers
      .filter((driver: Driver) => driver.isParticipating)
      .sort((a: Driver, b: Driver) => (a.lastname < b.lastname ? -1 : 1))
      .map((driver: Driver) => {
        return {
          value: driver.number,
          label: driver.lastname,
          color: driver.team.color,
        };
      });

    const getOption = (value: number | null): OptionTypeBase | undefined => {
      if (value === null) return undefined;

      return options.find((option: OptionTypeBase) => option.value === value);
    };

    const dot = (color = "#ccc") => ({
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: color,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 18,
        width: 4,
      },
    });

    const customStyles: Partial<Styles<any, false, GroupTypeBase<any>>> = {
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };

    const gridStartDriver: Driver | null =
      props.startingGridDriver !== null &&
      props.startingGridDriver !== undefined
        ? getDriver(props.startingGridDriver)
        : null;

    return (
      <StyledDriverSelector>
        <StyledPositionLabel>{props.label}</StyledPositionLabel>
        <StyledSelector>
          <Select
            defaultValue={getOption(props.value)}
            placeholder="select a driver"
            onChange={onChangeHandler}
            options={options}
            isClearable
            styles={customStyles}
          />
        </StyledSelector>
        <StyledStart>
          {gridStartDriver && (
            <StyledBadge backgroundColor={gridStartDriver.team.color}>
              {gridStartDriver.abbreviation}
            </StyledBadge>
          )}
        </StyledStart>
      </StyledDriverSelector>
    );
  };

  const getDriver = (driverNumber: number): Driver => {
    return (
      (drivers.find(
        (driver: Partial<Driver>) => driver.number == driverNumber
      ) as Driver) ?? ({} as Driver)
    );
  };

  const submit = (event: any) => {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    if (mode === Mode.Create) {
      Service.createRacePrediction(poolSlug, round, racePrediction!).then(
        (response: Response) => handleRacePredictionResponse(response)
      );
    } else {
      Service.updateRacePrediction(poolSlug, round, racePrediction!).then(
        (response: Response) => handleRacePredictionResponse(response)
      );
    }
  };

  const handleRacePredictionResponse = (response: Response) => {
    setIsLoading(false);
    response.json().then((body) => {
      if (response.status === 200 && body.isSuccess) {
        setSuccessMessage("Yes! your prediction is saved 🎉");
        setMode(Mode.Edit);
      } else {
        setErrorMessage(body.message);
      }
    });
  };

  const RacePredictionForm = () => {
    return (
      <>
        <Row style={{ marginTop: "40px" }}>
          <h2>
            <Flag
              code={raceInfo?.countryCode}
              height="28"
              style={{ border: "1px #aaa solid" }}
            />{" "}
            {raceInfo?.name}
          </h2>
        </Row>

        <Row className="justify-content-md-center" style={{ margin: "2rem" }}>
          <CountdownPill
            date={raceInfo?.raceStart!}
            label="until race start"
            variant="dark"
            completedText="Race has started"
            completedVariant="danger"
          />
        </Row>

        {raceInfo?.startingGrid && (
          <Row style={{ margin: "30px 0" }}>
            <Col style={{ textAlign: "right" }}>
              <Button
                variant="outline-primary"
                onClick={() => setShowStartingGrid(true)}
              >
                Show starting grid
              </Button>
            </Col>
          </Row>
        )}

        <StyledSelectionHeader>
          <StyledPositionHeader>Position</StyledPositionHeader>
          <StyledDriverHeader>Driver Prediction</StyledDriverHeader>
          <StyledStartHeader>Start</StyledStartHeader>
        </StyledSelectionHeader>

        <DriverSelector
          label="1st"
          value={racePrediction!.pos1}
          startingGridDriver={raceInfo?.startingGrid?.pos1}
          id={FormControl.pos1}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="2nd"
          value={racePrediction!.pos2}
          startingGridDriver={raceInfo?.startingGrid?.pos2}
          id={FormControl.pos2}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="3rd"
          value={racePrediction!.pos3}
          startingGridDriver={raceInfo?.startingGrid?.pos3}
          id={FormControl.pos3}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="4th"
          value={racePrediction!.pos4}
          startingGridDriver={raceInfo?.startingGrid?.pos4}
          id={FormControl.pos4}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="5th"
          value={racePrediction!.pos5}
          startingGridDriver={raceInfo?.startingGrid?.pos5}
          id={FormControl.pos5}
          onDriverChange={onDriverSelected}
        />
        {/* <DriverSelector
          label="6th"
          value={racePrediction!.pos6}
          startingGridDriver={raceInfo?.startingGrid?.pos6}
          id={FormControl.pos6}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="7th"
          value={racePrediction!.pos7}
          startingGridDriver={raceInfo?.startingGrid?.pos7}
          id={FormControl.pos7}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="8th"
          value={racePrediction!.pos8}
          startingGridDriver={raceInfo?.startingGrid?.pos8}
          id={FormControl.pos8}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="9th"
          value={racePrediction!.pos9}
          startingGridDriver={raceInfo?.startingGrid?.pos9}
          id={FormControl.pos9}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="10th"
          value={racePrediction!.pos10}
          startingGridDriver={raceInfo?.startingGrid?.pos10}
          id={FormControl.pos10}
          onDriverChange={onDriverSelected}
        /> */}

        <DriverSelector
          label="Fastest lap"
          startingGridDriver={null}
          value={racePrediction!.fastestLap}
          id={FormControl.fastest}
          onDriverChange={onDriverSelected}
        />

        <Row>
          <Col>
            {errorMessage ? (
              <DisplayMessage message={errorMessage} variant={"danger"} />
            ) : (
              ""
            )}
            {successMessage ? (
              <DisplayMessage message={successMessage} variant={"success"} />
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row style={{ margin: "1rem 0 3rem 0" }}>
          <StyledCenterButtonContainer>
            <Link to={`/overview/${poolSlug}`} style={{ marginRight: "1rem" }}>
              <Button variant="outline-primary">Go back</Button>
            </Link>
            <Button variant="danger" onClick={submit} disabled={isLoading}>
              {mode == Mode.Create && "Create"}
              {mode == Mode.Edit && "Update"}
            </Button>
          </StyledCenterButtonContainer>
        </Row>
      </>
    );
  };

  const StartingGridRow = (props: {
    position: string;
    driver: number | null;
  }) => {
    const driver: Driver | null =
      props.driver !== null ? getDriver(props.driver) : null;

    return (
      <tr>
        <td>{props.position}</td>
        <td>{driver?.lastname ?? "unknown"}</td>
      </tr>
    );
  };

  const DisplayMessage = (props: {
    message: string | null;
    variant: string;
  }) => {
    return <Alert variant={props.variant}>{props.message}</Alert>;
  };

  const RaceDoesNotExistMessage = () => {
    return (
      <>
        <h1>Race does not exist</h1>
        <p>
          <Link to="/">go back to home</Link>
        </p>
      </>
    );
  };

  return (
    <div>
      {pageState === PageState.Loading ? (
        <PageLoadingPlaceholder />
      ) : (
        <>
          <NavBar />
          <Container fluid="md">
            <Row className="justify-content-md-center">
              <Col lg={7}>
                {racePrediction && pageState == PageState.Done && (
                  <RacePredictionForm />
                )}
                {pageState === PageState.NotFound && (
                  <RaceDoesNotExistMessage />
                )}
              </Col>
            </Row>
          </Container>
        </>
      )}
      <Modal
        show={showStartingGrid}
        onHide={() => {
          setShowStartingGrid(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Starting Grid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {raceInfo?.startingGrid && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Driver</th>
                </tr>
              </thead>
              <tbody>
                <StartingGridRow
                  position="1"
                  driver={raceInfo.startingGrid.pos1}
                />
                <StartingGridRow
                  position="2"
                  driver={raceInfo.startingGrid.pos2}
                />
                <StartingGridRow
                  position="3"
                  driver={raceInfo.startingGrid.pos3}
                />
                <StartingGridRow
                  position="4"
                  driver={raceInfo.startingGrid.pos4}
                />
                <StartingGridRow
                  position="5"
                  driver={raceInfo.startingGrid.pos5}
                />
                <StartingGridRow
                  position="6"
                  driver={raceInfo.startingGrid.pos6}
                />
                <StartingGridRow
                  position="7"
                  driver={raceInfo.startingGrid.pos7}
                />
                <StartingGridRow
                  position="8"
                  driver={raceInfo.startingGrid.pos8}
                />
                <StartingGridRow
                  position="9"
                  driver={raceInfo.startingGrid.pos9}
                />
                <StartingGridRow
                  position="10"
                  driver={raceInfo.startingGrid.pos10}
                />
                <StartingGridRow
                  position="11"
                  driver={raceInfo.startingGrid.pos11}
                />
                <StartingGridRow
                  position="12"
                  driver={raceInfo.startingGrid.pos12}
                />
                <StartingGridRow
                  position="13"
                  driver={raceInfo.startingGrid.pos13}
                />
                <StartingGridRow
                  position="14"
                  driver={raceInfo.startingGrid.pos14}
                />
                <StartingGridRow
                  position="15"
                  driver={raceInfo.startingGrid.pos15}
                />
                <StartingGridRow
                  position="16"
                  driver={raceInfo.startingGrid.pos16}
                />
                <StartingGridRow
                  position="17"
                  driver={raceInfo.startingGrid.pos17}
                />
                <StartingGridRow
                  position="18"
                  driver={raceInfo.startingGrid.pos18}
                />
                <StartingGridRow
                  position="19"
                  driver={raceInfo.startingGrid.pos19}
                />
                <StartingGridRow
                  position="20"
                  driver={raceInfo.startingGrid.pos20}
                />
              </tbody>
            </Table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              setShowStartingGrid(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const StyledDriverSelector = styled.div`
  display: grid;
  grid-template-columns: [label] 70px [selector] 1fr [start] 40px;
  grid-column-gap: 10px;
  margin: 15px 0;
`;

const StyledPositionLabel = styled.div`
  grid-area: "label";
  font-weight: bold;
  align-self: center;
`;

const StyledSelector = styled.div`
  grid-area: "selector";
  align-self: center;
`;

const StyledStart = styled.div`
  grid-area: "start";
  align-self: center;
`;

interface BadgeProps {
  backgroundColor: string;
}

const StyledBadge = styled.span<BadgeProps>`
  font-family: sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #222;
  display: flex;

  &::before {
    background-color: ${(props) => props.backgroundColor};
    content: " ";
    display: block;
    margin-right: 6px;
    height: 15px;
    width: 3px;
  }
`;

const StyledSelectionHeader = styled.div`
  display: grid;
  grid-template-columns: [position] 70px [driver] 1fr [start] 40px;
  grid-gap: 10px;
  color: #999;
  font-size: 14px;
  font-weight: bold;
`;

const StyledPositionHeader = styled.div`
  grid-area: "position";
`;

const StyledDriverHeader = styled.div`
  grid-area: "driver";
`;

const StyledStartHeader = styled.div`
  grid-area: "start";
`;

const StyledCenterButtonContainer = styled.div`
  margin: 10px auto;
`;
