import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import Flag from "react-world-flags";
import styled from "styled-components";
import CountdownPill from "./CountdownPill";
import { race } from "../Models";
import { CalendarEvent } from "react-bootstrap-icons";

export interface IProps {
  nextRace: race;
  onShowFullSchedule: () => void;
}

export const UpcommingRaceCard = ({ nextRace, onShowFullSchedule }: IProps) => {
  const StyledContainer = styled(Container)`
    margin: 15px 0;
    font-family: "Formula1";
  `;

  const StyledTitle = styled(Card.Title)`
    font-size: 14px;
  `;

  const StyledCountDownPill = styled(CountdownPill)`
    font-weight: normal;
    font-size: 24px;
  `;

  const Centered = styled.div`
    margin: 0 auto;
    text-align: center;
  `;

  return (
    <StyledContainer>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <Card
            bg={"light"}
            text={"dark"}
            style={{ width: "19rem" }}
            className="mb-2"
          >
            <Card.Body>
              <StyledTitle>Next Race</StyledTitle>
              <Centered>
                <Flag code={nextRace.countryCode} height={18} />
                {"  "}
                {nextRace.name}
              </Centered>
              <Centered>
                <StyledCountDownPill
                  date={nextRace.raceStart}
                  label=""
                  variant="secondary"
                  completedText="Race started"
                  completedVariant="danger"
                />
              </Centered>
              <Centered>
                <Button
                  variant="outline-secondary"
                  onClick={() => onShowFullSchedule()}
                  size="sm"
                >
                  <CalendarEvent /> Show race calendar
                </Button>
              </Centered>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </StyledContainer>
  );
};
