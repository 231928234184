import { Badge } from "react-bootstrap";
import Countdown, { zeroPad } from "react-countdown";
import styled from "styled-components";

interface Props {
  date: Date;
  completedVariant: string;
  variant: string;
  label: string;
  completedText: string;
}

export const CountdownPill = ({
  date,
  completedVariant,
  variant,
  label,
  completedText,
}: Props) => {
  const StyledBadge = styled(Badge)`
    font-size: 12px;
    font-weight: normal;
    font-variant-numeric: tabular-nums;
  `;

  return (
    <Countdown
      date={date!}
      renderer={(props) => {
        if (props.completed) {
          return (
            <h5>
              <StyledBadge pill bg={completedVariant}>
                {completedText}
              </StyledBadge>
            </h5>
          );
        } else {
          if (props.days > 1) {
            return (
              <h5>
                <StyledBadge pill bg={variant}>
                  {props.days} days and {zeroPad(props.hours)}:
                  {zeroPad(props.minutes)}:{zeroPad(props.seconds)} {label}
                </StyledBadge>
              </h5>
            );
          } else if (props.days === 1) {
            return (
              <h5>
                <StyledBadge pill bg={variant}>
                  {props.days} day and {zeroPad(props.hours)}:
                  {zeroPad(props.minutes)}:{zeroPad(props.seconds)} {label}
                </StyledBadge>
              </h5>
            );
          } else if (props.hours >= 1) {
            return (
              <h5>
                <StyledBadge pill bg={variant}>
                  {zeroPad(props.hours)}:{zeroPad(props.minutes)}:
                  {zeroPad(props.seconds)} {label}
                </StyledBadge>
              </h5>
            );
          } else {
            return (
              <h5>
                <StyledBadge pill bg={variant}>
                  {zeroPad(props.minutes)}:{zeroPad(props.seconds)} {label}
                </StyledBadge>
              </h5>
            );
          }
        }
      }}
    />
  );
};

export default CountdownPill;
