import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import { Driver, pool, race, sprintQualifyingPrediction } from "../Models";
import Select, { GroupTypeBase, OptionTypeBase, Styles } from "react-select";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import { NavBar } from "../Components/NavBar";
import { Service } from "../Service";
import CountdownPill from "../Components/CountdownPill";
import { PageLoadingPlaceholder } from "../Components/PageLoadingPlaceholder";

enum FormControl {
  pos1 = "racePrediction.Pos1",
  pos2 = "racePrediction.Pos2",
  pos3 = "racePrediction.Pos3",
}

enum SaveMode {
  Create,
  Edit,
}

enum PageState {
  Loading,
  NotFound,
  Done,
}

export const SprintQualifyingPredictionPage = ({ match }: any) => {
  const {
    params: { poolSlug, round },
  } = match;

  const [saveMode, setSaveMode] = useState<SaveMode>();
  const [pageState, setPageState] = useState<PageState>(PageState.Loading);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pool, setPool] = useState<pool | null>(null);
  const [raceInfo, setRaceInfo] = useState<race>();
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [sprintQualifyingPrediction, setSprintQualifyingPrediction] =
    useState<sprintQualifyingPrediction>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    Service.getPool(poolSlug).then((response: Response) => {
      if (response.ok) {
        response.json().then((poolResult: pool) => {
          setPool(poolResult);
        })
      }
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (pool !== null) {
      Service.getDrivers(pool.divisionSlug).then((response: Response) => {
        if (response.ok) {
          response.json().then((driversResult: Driver[]) => {
            setDrivers(driversResult);
          });
        }
      });
      Service.fetchRace(pool.divisionSlug, round).then((response: Response) => {
        if (response.status === 200) {
          const responseBody = response.json();
          responseBody.then((info: race) => {
            setRaceInfo(info);
          });
        } else if (response.status === 404) {
          setPageState(PageState.NotFound);
        }
      });
      return () => {};
    }
  }, [pool]);

  useEffect(() => {
    if (raceInfo !== undefined) {
      Service.fetchSprintQualifyingPrediction(poolSlug, round).then(
        (response) => {
          if (response.ok) {
            response.json().then((prediction: sprintQualifyingPrediction) => {
              setSaveMode(SaveMode.Edit);
              setSprintQualifyingPrediction(prediction);
            });
          } else if (response.status === 404) {
            setSaveMode(SaveMode.Create);
            setSprintQualifyingPrediction({
              round: round,
            } as sprintQualifyingPrediction);
          }
          setPageState(PageState.Done);
        }
      );
    }
    return () => {};
  }, [raceInfo]);

  const onDriverSelected = (id: string, selectedDriver: number | null) => {
    let prediction = sprintQualifyingPrediction;

    if (prediction) {
      switch (id) {
        case FormControl.pos1:
          prediction.pos1 = selectedDriver;
          break;
        case FormControl.pos2:
          prediction.pos2 = selectedDriver;
          break;
        case FormControl.pos3:
          prediction.pos3 = selectedDriver;
          break;
      }
      setSprintQualifyingPrediction(prediction);
    }
  };

  const DriverSelector = (props: {
    label: string;
    value: number | null;
    onDriverChange: (id: string, selectedDriver: number | null) => void;
    id: FormControl;
  }) => {
    const onChangeHandler = (selectedOption: OptionTypeBase | null) => {
      props.onDriverChange(props.id, selectedOption?.value ?? null);
    };

    const options: OptionTypeBase[] = drivers
      .filter((driver: Driver) => driver.isParticipating)
      .sort((a: Driver, b: Driver) => (a.lastname < b.lastname ? -1 : 1))
      .map((driver: Driver) => {
        return {
          value: driver.number,
          label: driver.lastname,
          color: driver.team.color,
        };
      });

    const getOption = (value: number | null): OptionTypeBase | undefined => {
      if (value === null) return undefined;

      return options.find((option: OptionTypeBase) => option.value === value);
    };

    const dot = (color = "#ccc") => ({
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: color,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 18,
        width: 4,
      },
    });

    const customStyles: Partial<Styles<any, false, GroupTypeBase<any>>> = {
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };

    return (
      <Form.Group as={Row} controlId={props.id as string}>
        <Form.Label column sm={3} style={{ fontWeight: "bold" }}>
          {props.label}
        </Form.Label>
        <Col sm={9}>
          <Select
            defaultValue={getOption(props.value)}
            placeholder="select a driver"
            onChange={onChangeHandler}
            options={options}
            isClearable
            styles={customStyles}
          />
        </Col>
      </Form.Group>
    );
  };

  const submit = (event: any) => {
    event.preventDefault();

    setIsLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    if (saveMode === SaveMode.Create) {
      Service.createSprintQualifyingPrediction(
        poolSlug,
        round,
        sprintQualifyingPrediction!
      ).then((response: Response) => handleSaveResponse(response));
    } else if (saveMode === SaveMode.Edit) {
      Service.updateSprintQualifyingPrediction(
        poolSlug,
        round,
        sprintQualifyingPrediction!
      ).then((response: Response) => handleSaveResponse(response));
    }
  };

  const handleSaveResponse = (response: Response) => {
    setIsLoading(false);
    response.json().then((body) => {
      if (response.status === 200 && body.isSuccess) {
        setSuccessMessage("Yes! your sprint qualifying prediction is saved 🎉");
        setSaveMode(SaveMode.Edit);
      } else {
        setErrorMessage(body.message);
      }
    });
  };

  const SprintQualifyingPredictionForm = () => {
    return (
      <>
        <Row>
          <h1>Sprint Qualifying</h1>
        </Row>
        <Row>
          <h2>
            <Flag
              code={raceInfo?.countryCode}
              height="28"
              style={{ border: "1px #aaa solid" }}
            />{" "}
            {raceInfo?.name}
          </h2>
        </Row>

        <Row className="justify-content-md-center" style={{ margin: "2rem" }}>
          {raceInfo?.sprintQualifying?.start && (
            <CountdownPill
              date={raceInfo?.sprintQualifying?.start}
              label="until sprint start"
              variant="dark"
              completedText="Sprint has started"
              completedVariant="danger"
            />
          )}
        </Row>

        <DriverSelector
          label="1st"
          value={sprintQualifyingPrediction!.pos1}
          id={FormControl.pos1}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="2nd"
          value={sprintQualifyingPrediction!.pos2}
          id={FormControl.pos2}
          onDriverChange={onDriverSelected}
        />
        <DriverSelector
          label="3rd"
          value={sprintQualifyingPrediction!.pos3}
          id={FormControl.pos3}
          onDriverChange={onDriverSelected}
        />

        <Row>
          <Col>
            {errorMessage ? (
              <DisplayMessage message={errorMessage} variant={"danger"} />
            ) : (
              ""
            )}
            {successMessage ? (
              <DisplayMessage message={successMessage} variant={"success"} />
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row
          className="justify-content-md-center"
          style={{ marginTop: "1rem" }}
        >
          <Link to={`/overview/${poolSlug}`} style={{ marginRight: "1rem" }}>
            <Button variant="outline-primary">Go back</Button>
          </Link>
          <Button variant="danger" onClick={submit} disabled={isLoading}>
            {saveMode === SaveMode.Create && "Create"}
            {saveMode === SaveMode.Edit && "Update"}
          </Button>
        </Row>
      </>
    );
  };

  const DisplayMessage = (props: {
    message: string | null;
    variant: string;
  }) => {
    return <Alert variant={props.variant}>{props.message}</Alert>;
  };

  const RaceDoesNotExistMessage = () => {
    return (
      <>
        <h1>Sprint race does not exist</h1>
        <p>
          <Link to="/">go back to home</Link>
        </p>
      </>
    );
  };

  return (
    <div>
      {pageState === PageState.Loading ? (
        <PageLoadingPlaceholder />
      ) : (
        <>
          <NavBar />
          <Container fluid="md">
            <Row className="justify-content-md-center">
              <Col lg={7}>
                <Card style={{ marginTop: "3rem", padding: "1rem" }}>
                  <Card.Body>
                    {sprintQualifyingPrediction &&
                      pageState === PageState.Done && (
                        <SprintQualifyingPredictionForm />
                      )}
                    {pageState === PageState.NotFound && (
                      <RaceDoesNotExistMessage />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};
