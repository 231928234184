import { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { pool, slimUserDto, user } from "../Models";
import { NavBar } from "../Components/NavBar";
import { Service } from "../Service";
import Select, { OptionTypeBase } from "react-select";

enum PageState {
  Neutral,
  Unauthorized,
  NotFound,
}

export const AdminPoolPage = ({ match }: any) => {
  const {
    params: { poolSlug },
  } = match;

  const [pageState, setPageState] = useState<PageState>(PageState.Neutral);
  const [pool, setPool] = useState<pool>();
  const [members, setMembers] = useState<slimUserDto[]>();
  const [allUsers, setAllUsers] = useState<user[]>();
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [selectableUserOptions, setSelectableUserOptions] =
    useState<OptionTypeBase[]>();
  const [selectedUserOption, setSelectedUserOption] =
    useState<OptionTypeBase>();
  const [createMembershipError, setCreateMembershipError] = useState<string>();

  useEffect(() => {
    Service.getPool(poolSlug).then((response: Response) => {
      if (isUnautorized(response)) {
        setPageState(PageState.Unauthorized);
      } else if (response.status === 200) {
        response.json().then((poolResponse: pool) => {
          setPool(poolResponse);
        });
      } else if (response.status === 404) {
        setPageState(PageState.NotFound);
      }
    });
    Service.getAllUsers().then((response: Response) => {
      if (response.status === 200) {
        response.json().then((users: user[]) => {
          setAllUsers(users);
        });
      }
    });
    loadPoolMembers();
  }, [poolSlug]);

  useEffect(() => {
    if (members !== undefined && allUsers !== undefined) {
      const usersThatAreNotMembers = allUsers.filter(
        (user: user) =>
          members.find(
            (member: slimUserDto) => member.userId === user.userId
          ) === undefined
      );

      setSelectableUserOptions(
        usersThatAreNotMembers.map((user: user) => {
          return { label: `${user.name} (${user.email})`, value: user.userId };
        })
      );
    }
  }, [members, allUsers]);

  const isUnautorized = (response: Response) => {
    return response.status === 401 || response.status === 403;
  };

  const AdminPageContent = () => {
    return (
      <>
        <h1>{pool?.name}</h1>

        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <h3>Members</h3>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button variant="success" onClick={openAddUserModel}>
              Add User
            </Button>
          </Col>
        </Row>

        <Table striped bordered>
          <thead>
            <tr>
              <th>User</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {members &&
              members.map((user: slimUserDto) => {
                return <UserRow key={`key-${user.userId}`} user={user} />;
              })}
          </tbody>
        </Table>
      </>
    );
  };

  const UserRow = (props: { key: string; user: slimUserDto }) => {
    return (
      <tr>
        <td>
          {props.user.name} <Badge bg="primary">{props.user.email}</Badge>
        </td>
        <td style={{ textAlign: "right" }}>
          <Button
            variant="outline-danger"
            onClick={() => removePoolMember(props.user.userId)}
          >
            Remove
          </Button>
        </td>
      </tr>
    );
  };

  const removePoolMember = (userId: string) => {
    if (pool)
      Service.removePoolMember(pool.slug, userId).then((response: Response) => {
        if (response.status === 200) {
          loadPoolMembers();
        }
      });
  };

  const openAddUserModel = () => {
    setShowAddMemberModal(true);
  };

  const handleCloseAddUserModal = () => {
    setShowAddMemberModal(false);
    setSelectedUserOption(undefined);
    setCreateMembershipError(undefined);
  };

  const createUserMembership = () => {
    if (selectedUserOption !== undefined && pool?.slug) {
      Service.addPoolMember(pool.slug, selectedUserOption.value).then(
        (response: Response) => {
          if (response.status === 200) {
            loadPoolMembers();
            handleCloseAddUserModal();
          } else {
            response.text().then((message: string) => {
              setCreateMembershipError(`HTTP ${response.status} | ${message}`);
            });
          }
        }
      );
    }
  };

  const loadPoolMembers = () => {
    Service.getPoolMembers(poolSlug).then((response: Response) => {
      if (isUnautorized(response)) {
        setPageState(PageState.Unauthorized);
      } else if (response.status === 200) {
        response.json().then((poolMembers: slimUserDto[]) => {
          setMembers(poolMembers);
        });
      }
    });
  };

  const UnAuthorized = () => {
    return (
      <>
        <h1>Unauthorized</h1>
        <p>
          <Link to="/overview">go back to home</Link>
        </p>
      </>
    );
  };

  const NotFoundMessage = () => {
    return (
      <>
        <h1>Not Found</h1>
        <p>
          <Link to="/overview">go back to home</Link>
        </p>
      </>
    );
  };

  const onNewUserSelectionChange = (
    selectedOption: OptionTypeBase | undefined
  ) => {
    setSelectedUserOption(selectedOption);
  };

  return (
    <>
      <NavBar />
      <Container fluid="md" style={{ marginTop: "50px" }}>
        <Row className="justify-content-md-center">
          <Col lg={7}>
            {pageState === PageState.Neutral && <AdminPageContent />}
            {pageState === PageState.Unauthorized && <UnAuthorized />}
            {pageState === PageState.NotFound && <NotFoundMessage />}
          </Col>
        </Row>
      </Container>

      <Modal show={showAddMemberModal} onHide={handleCloseAddUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Select user to join the pool</Form.Label>
              <Select
                value={selectedUserOption}
                placeholder="select new member"
                onChange={onNewUserSelectionChange}
                options={selectableUserOptions}
                isClearable
              />
            </Form.Group>
          </Form>
          {createMembershipError && (
            <Alert variant={"danger"}>{createMembershipError}</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddUserModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={createUserMembership}
            disabled={selectedUserOption === undefined}
          >
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
