import styled from "styled-components";
import { Driver, startingGrid } from "../Models";

export interface IProps {
  startingGrid: startingGrid | null;
  getDriverByNumber: (
    driverNumber: number | undefined | null
  ) => Driver | undefined;
}

export const StartingGrid = ({ startingGrid, getDriverByNumber }: IProps) => {
  const GridContainer = styled.div`
    display: flex;
    justify-content: center;
    /* align-items: center; */
    box-sizing: border-box;
    max-width: 105px;
    padding: 10px 0;
  `;

  const GridLeftSide = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
    margin-right: 5px;
    margin-bottom: 8px;
  `;

  const GridRightSide = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
    margin-top: 8px;
  `;

  const StyledGridPosition = styled.div`
    width: 44px;
    height: 30px;
    text-align: center
    line-height: 20px;
    font-size: 10px;
    `;

  const StyledGridNumber = styled.div`
    text-align: center;
    color: #555;
  `;

  const StyledGridSlot = styled.div`
    border-radius: 4px 4px 0 0;
    border: 1px solid #bbb;
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const StyledDriver = styled.div<{ teamColor: string }>`
    font-weight: bold;
    border-left: 3px solid ${(props) => props.teamColor};
    font-size: 10px;
    position: relative;
    top: 3px;
    padding: 0 0 0 3px;
    line-height: 1;
    color: #333;
  `;

  const GridPosition = (props: {
    position: string;
    driverNumber: number | null | undefined;
  }) => {
    const driver = getDriverByNumber(props.driverNumber);
    return (
      <StyledGridPosition>
        <StyledGridNumber>{props.position}</StyledGridNumber>
        <StyledGridSlot>
          {driver ? (
            <StyledDriver teamColor={driver.team.color}>
              {driver.abbreviation}
            </StyledDriver>
          ) : (
            <StyledDriver teamColor="#999">?</StyledDriver>
          )}
        </StyledGridSlot>
      </StyledGridPosition>
    );
  };

  return (
    <GridContainer>
      <GridLeftSide>
        <GridPosition position="1" driverNumber={startingGrid?.pos1} />
        <GridPosition position="3" driverNumber={startingGrid?.pos3} />
        <GridPosition position="5" driverNumber={startingGrid?.pos5} />
        <GridPosition position="7" driverNumber={startingGrid?.pos7} />
        <GridPosition position="9" driverNumber={startingGrid?.pos9} />
        <GridPosition position="11" driverNumber={startingGrid?.pos11} />
        <GridPosition position="13" driverNumber={startingGrid?.pos13} />
        <GridPosition position="15" driverNumber={startingGrid?.pos15} />
        <GridPosition position="17" driverNumber={startingGrid?.pos17} />
        <GridPosition position="19" driverNumber={startingGrid?.pos19} />
      </GridLeftSide>
      <GridRightSide>
        <GridPosition position="2" driverNumber={startingGrid?.pos2} />
        <GridPosition position="4" driverNumber={startingGrid?.pos4} />
        <GridPosition position="6" driverNumber={startingGrid?.pos6} />
        <GridPosition position="8" driverNumber={startingGrid?.pos8} />
        <GridPosition position="10" driverNumber={startingGrid?.pos10} />
        <GridPosition position="12" driverNumber={startingGrid?.pos12} />
        <GridPosition position="14" driverNumber={startingGrid?.pos14} />
        <GridPosition position="16" driverNumber={startingGrid?.pos16} />
        <GridPosition position="18" driverNumber={startingGrid?.pos18} />
        <GridPosition position="20" driverNumber={startingGrid?.pos20} />
      </GridRightSide>
    </GridContainer>
  );
};
