import styled from "styled-components";
import { userStanding } from "../Models";

interface IProps {
    first?: userStanding;
    second?: userStanding;
    third?: userStanding;
};

export const Podium = (props: IProps) => {
    const { first, second, third } = props;

    const StyledPodium = styled.div`
        display: grid;
        grid-template-columns: [second] auto [first] auto [third] auto;
        margin: 20px 0;
    `;

    const StyledPodiumFinisher = styled.div`
        text-align: center;
        padding: 0 5px 3px 5px;
        font-weight: bold;
        font-size: 20px;
    `;

    const StyledPodiumPoints = styled.div`
        text-align: center;
        padding: 0 5px 25px 5px;
        font-size: 18px;
    `;

    const StyledPodiumSecond = styled.div`
        display: grid;
        grid-area: "second";
        align-self: end;
    `;

    const StyledPodiumSecondStep = styled.div`
        display: grid;
        background-color: #fff;
        text-align: center;
        height: 60px;
        box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
        border-radius: 5px 0 0 5px;
        align-content: center;
        font-size: 30px;
        font-weight: bold;
        color: #b4b8bf;
    `;

    const StyledPodiumFirst = styled.div`
        display: grid;
        grid-area: "first";
        align-self: end;
    `;

    const StyledPodiumFirstStep = styled.div`
        display: grid;
        background-color: #fff;
        text-align: center;
        height: 80px;
        box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.3);
        border-radius: 5px 5px 0 0;
        z-index: 20;
        align-content: center;
        font-size: 40px;
        font-weight: bold;
        color: #bfa145;
    `;

    const StyledPodiumThird = styled.div`
        display: grid;
        grid-area: "third";
        align-self: end;
    `;

    const StyledPodiumThirdStep = styled.div`
        display: grid;
        background-color: #fff;
        text-align: center;
        height: 40px;
        box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
        border-radius: 0 5px 5px 0;
        align-content: center;
        font-size: 24px;
        font-weight: bold;
        color: #594828;
    `;

    return (
        <StyledPodium>
            <StyledPodiumSecond>
            <StyledPodiumFinisher>
                {second?.name}
            </StyledPodiumFinisher>
            <StyledPodiumPoints>
                {second?.totalPoints} points
            </StyledPodiumPoints>
            <StyledPodiumSecondStep>2</StyledPodiumSecondStep>
            </StyledPodiumSecond>
            <StyledPodiumFirst>
            <StyledPodiumFinisher>
                {first?.name}
            </StyledPodiumFinisher>
            <StyledPodiumPoints>
                {first?.totalPoints} points
            </StyledPodiumPoints>
            <StyledPodiumFirstStep>1</StyledPodiumFirstStep>
            </StyledPodiumFirst>
            <StyledPodiumThird>
            <StyledPodiumFinisher>
                {third?.name}
            </StyledPodiumFinisher>
            <StyledPodiumPoints>
                {third?.totalPoints} points
            </StyledPodiumPoints>
            <StyledPodiumThirdStep>3</StyledPodiumThirdStep>
            </StyledPodiumThird>
        </StyledPodium>
    );
};

export default Podium;