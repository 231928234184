import { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { NavBar } from "../Components/NavBar";
import { PageLoadingPlaceholder } from "../Components/PageLoadingPlaceholder";
import { pool } from "../Models";
import { Service } from "../Service";

enum PageState {
  Loading,
  Error,
}

export const Join = ({ match }: any) => {
  const {
    params: { poolSlug },
  } = match;

  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const [errorResponse, setErrorResponse] = useState<{
    status: number;
    message: string;
  }>();

  useEffect(() => {
    if (poolSlug !== undefined) {
      Service.joinPool(poolSlug).then((response: Response) => {
        if (response.status === 200) {
          response.json().then((pool: pool) => {
            redirectToPool(pool);
          });
        } else {
          response.text().then((message: string) => {
            setErrorResponse({
              status: response.status,
              message: message,
            });
            setPageState(PageState.Error);
          });
        }
      });
    }
  }, [poolSlug]);

  const redirectToPool = (pool: pool | undefined) => {
    if (pool) {
      window.location.replace(`/pool/${pool.slug}`);
    }
  };

  const ErrorMessage = () => {
    return (
      <Card style={{ marginTop: "3rem" }}>
        <Card.Header>Error</Card.Header>
        <Card.Body>{`HTTP ${errorResponse?.status} | ${errorResponse?.message}`}</Card.Body>
      </Card>
    );
  };

  return (
    <div>
      {pageState === PageState.Loading ? (
        <PageLoadingPlaceholder />
      ) : (
        <>
          <NavBar />
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col>{pageState === PageState.Error && <ErrorMessage />}</Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};
