import {
  createPoolDto,
  createRace,
  race,
  racePrediction,
  sprintQualifyingPrediction,
  user,
} from "./Models";
import settings from "./settings";

export class Service {
  public static getToken = (): string => {
    const token = localStorage.getItem("access_token");
    if (token === null) {
      window.location.href = `/`; // Redirect to home for the user to sign-in
      return "";
    }
    return token;
  };

  public static fetchUserInfo = async () => {
    const token = Service.getToken();
    const response = await fetch(`${settings.f1PoolBaseUri}/User/Info`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  public static getAllUsers = async () => {
    const token = Service.getToken();
    const response = await fetch(`${settings.f1PoolBaseUri}/Admin/Users`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  public static getDivisions = async () => {
    const token = Service.getToken();
    const response = await fetch(`${settings.f1PoolBaseUri}/Division/All`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  public static getPool = async (poolSlug: string) => {
    const token = Service.getToken();
    const response = await fetch(`${settings.f1PoolBaseUri}/Pool/${poolSlug}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  public static joinPool = async (poolSlug: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/Join`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    );
    return response;
  };

  public static createPool = async (newPool: createPoolDto) => {
    const token = Service.getToken();
    const response = await fetch(`${settings.f1PoolBaseUri}/Admin/Pool`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(newPool),
    });
    return response;
  };

  public static getPoolMembers = async (poolSlug: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Admin/Pool/${poolSlug}/Members`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static addPoolMember = async (poolSlug: string, userId: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Admin/Pool/${poolSlug}/AddMember/${userId}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
        method: "POST",
      }
    );
    return response;
  };

  public static removePoolMember = async (poolSlug: string, userId: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Admin/Pool/${poolSlug}/RemoveMember/${userId}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
        method: "DELETE",
      }
    );
    return response;
  };

  public static getExtendedDivision = async (divisionSlug: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Division/${divisionSlug}/extended`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static fetchRaces = async (divisionSlug: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Division/${divisionSlug}/Races`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static fetchRace = async (divisionSlug: string, round: number) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Division/${divisionSlug}/Race/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static getDrivers = async (divisionSlug: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Division/${divisionSlug}/Drivers`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static getNextRaceRoundNumber = async (divisionSlug: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Admin/Division/${divisionSlug}/NextRoundNumber`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return await response.text();
  };

  public static createRace = async (
    divisionSlug: string,
    newRace: createRace
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Admin/Division/${divisionSlug}/Race`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(newRace),
      }
    );
    return response;
  };

  public static updateRace = async (
    divisionSlug: string,
    round: number,
    raceDto: race
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Admin/Division/${divisionSlug}/Race/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(raceDto),
      }
    );
    return response.status;
  };

  public static updateUser = async (updatedUser: user) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Admin/Users/${updatedUser.userId}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(updatedUser),
      }
    );
    return response;
  };

  public static fetchRaceParticipants = async (
    poolSlug: string,
    round: number
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/RaceParticipants/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static fetchPoolOverview = async (poolSlug: string) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/PoolOverview`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static getLatestLiveRaceEvent = async (
    poolSlug: string,
    raceId: string,
    lastSequence: number | null
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${
        settings.f1PoolBaseUri
      }/Pool/${poolSlug}/LiveRace/${raceId}/GetLatestEvent?latestSequence=${
        lastSequence === null ? 0 : lastSequence
      }`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static fetchRacePrediction = async (
    poolSlug: string,
    round: number
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/RacePrediction/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static createRacePrediction = async (
    poolSlug: string,
    round: number,
    racePrediction: racePrediction
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/RacePrediction/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(racePrediction),
      }
    );
    return response;
  };

  public static updateRacePrediction = async (
    poolSlug: string,
    round: number,
    racePrediction: racePrediction
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/RacePrediction/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(racePrediction),
      }
    );
    return response;
  };

  public static updateRacePredictionForAllPools = async (
    divisionSlug: string,
    round: number,
    racePrediction: racePrediction
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/ForAllPoolsInDivision/${divisionSlug}/RacePrediction/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(racePrediction),
      }
    );
    return response;
  };

  public static fetchSprintQualifyingPrediction = async (
    poolSlug: string,
    round: number
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/SprintQualifyingPrediction/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  public static createSprintQualifyingPrediction = async (
    poolSlug: string,
    round: number,
    prediction: sprintQualifyingPrediction
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/SprintQualifyingPrediction/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "PUT",
        body: JSON.stringify(prediction),
      }
    );
    return response;
  };

  public static updateSprintQualifyingPrediction = async (
    poolSlug: string,
    round: number,
    prediction: sprintQualifyingPrediction
  ) => {
    const token = Service.getToken();
    const response = await fetch(
      `${settings.f1PoolBaseUri}/Pool/${poolSlug}/SprintQualifyingPrediction/${round}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(prediction),
      }
    );
    return response;
  };
}
