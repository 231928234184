import { useState, useEffect } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Driver, race, raceResult, startingGrid, sprintQualifying } from '../Models';
import Select, { GroupTypeBase, OptionTypeBase, Styles } from 'react-select';
import Flag from 'react-world-flags'
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import EditableField from '../Components/EditableField';
import EditableNumberField from '../Components/EditableNumberField';
import { NavBar } from '../Components/NavBar';
import { Service } from '../Service';

enum FormControl {
    pos1 = "raceResult.Pos1",
    pos2 = "raceResult.Pos2",
    pos3 = "raceResult.Pos3",
    pos4 = "raceResult.Pos4",
    pos5 = "raceResult.Pos5",
    pos6 = "raceResult.Pos6",
    pos7 = "raceResult.Pos7",
    pos8 = "raceResult.Pos8",
    pos9 = "raceResult.Pos9",
    pos10 = "raceResult.Pos10",
    fastest = "raceResult.Fastest",
    sqPos1 = "raceQualifying.Pos1",
    sqPos2 = "raceQualifying.Pos2",
    sqPos3 = "raceQualifying.Pos3",
    gridPos1 = "raceResult.GridPos1",
    gridPos2 = "raceResult.GridPos2",
    gridPos3 = "raceResult.GridPos3",
    gridPos4 = "raceResult.GridPos4",
    gridPos5 = "raceResult.GridPos5",
    gridPos6 = "raceResult.GridPos6",
    gridPos7 = "raceResult.GridPos7",
    gridPos8 = "raceResult.GridPos8",
    gridPos9 = "raceResult.GridPos9",
    gridPos10 = "raceResult.GridPos10",
    gridPos11 = "raceResult.GridPos11",
    gridPos12 = "raceResult.GridPos12",
    gridPos13 = "raceResult.GridPos13",
    gridPos14 = "raceResult.GridPos14",
    gridPos15 = "raceResult.GridPos15",
    gridPos16 = "raceResult.GridPos16",
    gridPos17 = "raceResult.GridPos17",
    gridPos18 = "raceResult.GridPos18",
    gridPos19 = "raceResult.GridPos19",
    gridPos20 = "raceResult.GridPos20",
}

export const EditRacePage = ({ match }: any) => {
    const { params: { 
        divisionSlug,
        round
    }} = match;
    
    const [drivers, setDrivers] = useState<Driver[]>([]);
    const [race, setRace] = useState<race>();

    const [raceRound, setRaceRound] = useState<number>();
    const [raceName, setRaceName] = useState<string>();
    const [raceFullName, setRaceFullName] = useState<string>();
    const [raceShortName, setRaceShortName] = useState<string>();
    const [raceCountry, setRaceCountry] = useState<string>();
    const [raceCountryCode, setRaceCountryCode] = useState<string>();
    const [raceStart, setRaceStart] = useState<Date>();

    const [showStartingGrid, setShowStartingGrid] = useState<boolean>(false);
    const [startingGrid, setStartingGrid] = useState<startingGrid>();

    const [raceResult, setRaceResult] = useState<raceResult>();

    const [hasSprintQualifying, setHasSprintQualifying] = useState<boolean>();
    const [sprintQualifyingStart, setSprintQualifyingStart] = useState<Date>();
    const [sprintQualifying, setSprintQualifying] = useState<sprintQualifying>();
    
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    useEffect(() => {
        Service.getDrivers(divisionSlug).then((response: Response) => {
            if (response.ok) {
                response.json().then((driversResult: Driver[]) => {
                    setDrivers(driversResult);
                });
            }
        });
        Service.fetchRace(divisionSlug, round)
            .then((response: Response) => {
                if (response.status === 200) {
                    response.json().then((race: race) => {
                        setRace(race);
                        
                        setRaceRound(race.round);
                        setRaceName(race.name);
                        setRaceFullName(race.fullName);
                        setRaceShortName(race.shortName);
                        setRaceCountry(race.country);
                        setRaceCountryCode(race.countryCode);
                        setRaceStart(new Date(race.raceStart));
    
                        setHasSprintQualifying(race.sprintQualifying !== null);
                        setSprintQualifyingStart(race.sprintQualifying ? new Date(race.sprintQualifying?.start) : new Date())
                        setSprintQualifying(race.sprintQualifying ?? {} as sprintQualifying);
    
                        const startingGrid: startingGrid = race.startingGrid !== null ? race.startingGrid : {} as startingGrid;
                        setStartingGrid(startingGrid);
    
                        const raceResult: raceResult = race.result !== null ? race.result : {} as raceResult;
                        setRaceResult(raceResult);
                    });
                }
            });
        return () => {
        }
    }, []);

    const onStartingGridDriverSelected = (id: string, selectedDriver: number | null) => {
        let startingGridCopy = startingGrid;

        if (startingGridCopy) {
            switch (id) {
                case FormControl.gridPos1:
                    startingGridCopy.pos1 = selectedDriver;
                    break;
                case FormControl.gridPos2:
                    startingGridCopy.pos2 = selectedDriver;
                    break;
                case FormControl.gridPos3:
                    startingGridCopy.pos3 = selectedDriver;
                    break;
                case FormControl.gridPos4:
                    startingGridCopy.pos4 = selectedDriver;
                    break;
                case FormControl.gridPos5:
                    startingGridCopy.pos5 = selectedDriver;
                    break;
                case FormControl.gridPos6:
                    startingGridCopy.pos6 = selectedDriver;
                    break;
                case FormControl.gridPos7:
                    startingGridCopy.pos7 = selectedDriver;
                    break;
                case FormControl.gridPos8:
                    startingGridCopy.pos8 = selectedDriver;
                    break;
                case FormControl.gridPos9:
                    startingGridCopy.pos9 = selectedDriver;
                    break;
                case FormControl.gridPos10:
                    startingGridCopy.pos10 = selectedDriver;
                    break;
                case FormControl.gridPos11:
                    startingGridCopy.pos11 = selectedDriver;
                    break;
                case FormControl.gridPos12:
                    startingGridCopy.pos12 = selectedDriver;
                    break;
                case FormControl.gridPos13:
                    startingGridCopy.pos13 = selectedDriver;
                    break;
                case FormControl.gridPos14:
                    startingGridCopy.pos14 = selectedDriver;
                    break;
                case FormControl.gridPos15:
                    startingGridCopy.pos15 = selectedDriver;
                    break;
                case FormControl.gridPos16:
                    startingGridCopy.pos16 = selectedDriver;
                    break;
                case FormControl.gridPos17:
                    startingGridCopy.pos17 = selectedDriver;
                    break;
                case FormControl.gridPos18:
                    startingGridCopy.pos18 = selectedDriver;
                    break;
                case FormControl.gridPos19:
                    startingGridCopy.pos19 = selectedDriver;
                    break;
                case FormControl.gridPos20:
                    startingGridCopy.pos20 = selectedDriver;
                    break;
            }
            setStartingGrid(startingGridCopy);
        }
    }

    const onDriverSelected = (id: string, selectedDriver: number | null) => {
        let raceResultCopy = raceResult;

        if (raceResultCopy) {
            switch (id) {
                case FormControl.pos1:
                    raceResultCopy.pos1 = selectedDriver;
                    break;
                case FormControl.pos2:
                    raceResultCopy.pos2 = selectedDriver;
                    break;
                case FormControl.pos3:
                    raceResultCopy.pos3 = selectedDriver;
                    break;
                case FormControl.pos4:
                    raceResultCopy.pos4 = selectedDriver;
                    break;
                case FormControl.pos5:
                    raceResultCopy.pos5 = selectedDriver;
                    break;
                case FormControl.pos6:
                    raceResultCopy.pos6 = selectedDriver;
                    break;
                case FormControl.pos7:
                    raceResultCopy.pos7 = selectedDriver;
                    break;
                case FormControl.pos8:
                    raceResultCopy.pos8 = selectedDriver;
                    break;
                case FormControl.pos9:
                    raceResultCopy.pos9 = selectedDriver;
                    break;
                case FormControl.pos10:
                    raceResultCopy.pos10 = selectedDriver;
                    break;
                case FormControl.fastest:
                    raceResultCopy.fastestLap = selectedDriver;
                    break;
            }
            setRaceResult(raceResultCopy);
        }
    }

    const onSprintQualifyingDriverSelected = (id: string, selectedDriver: number | null) => {
        let sprintQualifyingCopy = sprintQualifying;

        if (sprintQualifyingCopy) {
            switch (id) {
                case FormControl.sqPos1:
                    sprintQualifyingCopy.pos1 = selectedDriver;
                    break;
                case FormControl.sqPos2:
                    sprintQualifyingCopy.pos2 = selectedDriver;
                    break;
                case FormControl.sqPos3:
                    sprintQualifyingCopy.pos3 = selectedDriver;
                    break;
            }
            setSprintQualifying(sprintQualifyingCopy);
        }
    }

    const DriverSelector = (props: {label: string, value: number | null, onDriverChange: (id: string, selectedDriver: number | null) => void, id: FormControl}) => {
        const onChangeHandler = (selectedOption: OptionTypeBase | null) => {
            props.onDriverChange(props.id, selectedOption?.value ?? null);
        }

        const options: OptionTypeBase[] = drivers.filter((driver: Driver) => driver.isParticipating)
                                                 .sort((a: Driver, b: Driver) => a.lastname < b.lastname ? -1 : 1)
                                                 .map((driver: Driver) => {
                                                    return {
                                                        value: driver.number, 
                                                        label: driver.lastname, 
                                                        color: driver.team.color 
                                                    }});

        const getOption = (value: number | null): OptionTypeBase | undefined => {
            if (value === null)
                return undefined;
            
            return options.find((option: OptionTypeBase) => option.value === value);
        }

        const dot = (color = '#ccc') => ({
            alignItems: 'center',
            display: 'flex',
          
            ':before': {
              backgroundColor: color,
              content: '" "',
              display: 'block',
              marginRight: 8,
              height: 18,
              width: 4,
            },
          });

        const customStyles: Partial<Styles<any, false, GroupTypeBase<any>>> = {
            singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) })
        };

        return (
            <Form.Group as={Row} controlId={props.id as string}>
                <Form.Label column sm={3} style={{fontWeight: 'bold'}}>
                    {props.label}
                </Form.Label>
                <Col sm={9}>
                    <Select
                        defaultValue={getOption(props.value)}
                        placeholder="select a driver"
                        onChange={onChangeHandler}
                        options={options}
                        isClearable
                        styles={customStyles}
                    />
                </Col>
            </Form.Group>
        );
    }

    const getTimezoneAgnosticDate = (date: Date | undefined): Date => {
        const userTimezoneOffset = date!.getTimezoneOffset() * 60000;
        return new Date(date!.getTime() - userTimezoneOffset);
    }

    const goBackLink = () => {
        window.location.href = `/Admin/Division/${divisionSlug}`;
    };

    const submit = (event: any) => {
        event.preventDefault();

        setErrorMessage(null);
        setSuccessMessage(null);

        const userTimezoneOffset = raceStart!.getTimezoneOffset() * 60000;
        const timezoneAgnosticRaceStart = new Date(raceStart!.getTime() - userTimezoneOffset);

        let sprintQualifyingValue: sprintQualifying | null = null;
        if (hasSprintQualifying) {
            sprintQualifyingValue = {
                start: getTimezoneAgnosticDate(sprintQualifyingStart),
                pos1: sprintQualifying?.pos1,
                pos2: sprintQualifying?.pos2,
                pos3: sprintQualifying?.pos3
            } as sprintQualifying;
        }

        let updatedRace: race = {
            raceId: race!.raceId,
            division: race!.division,
            round: raceRound!,
            name: raceName!,
            fullName: raceFullName!,
            shortName: raceShortName!,
            country: raceCountry!,
            countryCode: raceCountryCode!,
            raceStart: timezoneAgnosticRaceStart,
            startingGrid: startingGrid!,
            result: raceResult!,
            sprintQualifying: sprintQualifyingValue
        };

        Service.updateRace(divisionSlug, round, updatedRace!)
            .then((responseCode) => {
                if (responseCode === 200) {
                    setSuccessMessage("Yes! race is updated 🎉");
                } else if (responseCode === 403) {
                    setErrorMessage("Not allowed");
                } else {
                    setErrorMessage(`Response code was ${responseCode}`);
                }
            });
    }

    const DisplayMessage = (props: {message: string | null, variant: string}) => {
        return (
            <Alert variant={props.variant}>{props.message}</Alert>
        );
    }

    const UnAuthorized = () => {
        return (
            <>
                <h1>Loading</h1>
                <p>If loading takes too long <Link to="/overview" >go back to home</Link></p>
            </>
        )
    };

    return (
        <div>
            <NavBar />
            <Container fluid="md">
                <Row className="justify-content-md-center">
                    <Col lg={7}>
                        <Card style={{marginTop: '3rem', padding: '1rem'}}>
                            <Card.Body>
                                { raceResult ? 
                                    <>
                                        <Row>
                                            <h3>Edit: <Flag code={raceCountryCode!} height="28" style={{border: "1px #aaa solid"}} />  {raceName!}</h3>
                                        </Row>

                                        <EditableField label="Division" value={race?.division.name} disabled={true} />

                                        <EditableNumberField label="Round" value={raceRound} onChange={setRaceRound} />
                                        <EditableField label="Name" value={raceName} onChange={setRaceName} />
                                        <EditableField label="Full name" value={raceFullName} onChange={setRaceFullName} />
                                        <EditableField label="Short name" value={raceShortName} onChange={setRaceShortName} />
                                        <EditableField label="Country" value={raceCountry} onChange={setRaceCountry} />
                                        <EditableField label="Country code" value={raceCountryCode} onChange={setRaceCountryCode} />

                                        <Form.Group as={Row}>
                                            <Form.Label column sm={3} style={{fontWeight: 'bold'}}>
                                                Race start
                                            </Form.Label>
                                            <Col>
                                                <DatePicker 
                                                    selected={raceStart ? new Date(raceStart) : null} 
                                                    onChange={(newRaceStart) => setRaceStart(newRaceStart as Date)}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={5}
                                                    timeCaption="time"
                                                    dateFormat="dd-MM-yyyy HH:mm"
                                                />
                                            </Col>
                                        </Form.Group>

                                        <h4>Starting Grid</h4>
                                        <Form.Group className="mb-3">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Show starting grid" 
                                                checked={showStartingGrid}
                                                onChange={(event) => setShowStartingGrid(event.target.checked)} 
                                            />
                                        </Form.Group>
                                        { showStartingGrid && (
                                            <>
                                                <DriverSelector 
                                                    label="1" 
                                                    value={startingGrid!.pos1} 
                                                    id={FormControl.gridPos1} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="2" 
                                                    value={startingGrid!.pos2} 
                                                    id={FormControl.gridPos2} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="3" 
                                                    value={startingGrid!.pos3} 
                                                    id={FormControl.gridPos3} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="4" 
                                                    value={startingGrid!.pos4} 
                                                    id={FormControl.gridPos4} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="5" 
                                                    value={startingGrid!.pos5} 
                                                    id={FormControl.gridPos5} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="6" 
                                                    value={startingGrid!.pos6} 
                                                    id={FormControl.gridPos6} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="7" 
                                                    value={startingGrid!.pos7} 
                                                    id={FormControl.gridPos7} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="8" 
                                                    value={startingGrid!.pos8} 
                                                    id={FormControl.gridPos8} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="9" 
                                                    value={startingGrid!.pos9} 
                                                    id={FormControl.gridPos9} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="10" 
                                                    value={startingGrid!.pos10} 
                                                    id={FormControl.gridPos10} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="11" 
                                                    value={startingGrid!.pos11} 
                                                    id={FormControl.gridPos11} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="12" 
                                                    value={startingGrid!.pos12} 
                                                    id={FormControl.gridPos12} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="13" 
                                                    value={startingGrid!.pos13} 
                                                    id={FormControl.gridPos13} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="14" 
                                                    value={startingGrid!.pos14} 
                                                    id={FormControl.gridPos14} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="15" 
                                                    value={startingGrid!.pos15} 
                                                    id={FormControl.gridPos15} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="16" 
                                                    value={startingGrid!.pos16} 
                                                    id={FormControl.gridPos16} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="17" 
                                                    value={startingGrid!.pos17} 
                                                    id={FormControl.gridPos17} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="18" 
                                                    value={startingGrid!.pos18} 
                                                    id={FormControl.gridPos18} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="19" 
                                                    value={startingGrid!.pos19} 
                                                    id={FormControl.gridPos19} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                                <DriverSelector 
                                                    label="20" 
                                                    value={startingGrid!.pos20} 
                                                    id={FormControl.gridPos20} 
                                                    onDriverChange={onStartingGridDriverSelected}
                                                />
                                            </>
                                        )}

                                        
                                        <h4>Race Result</h4>
                                        <DriverSelector 
                                            label="1st" 
                                            value={raceResult!.pos1} 
                                            id={FormControl.pos1} 
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="2nd" 
                                            value={raceResult!.pos2} 
                                            id={FormControl.pos2} 
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="3rd" 
                                            value={raceResult!.pos3} 
                                            id={FormControl.pos3}
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="4th" 
                                            value={raceResult!.pos4} 
                                            id={FormControl.pos4}
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="5th" 
                                            value={raceResult!.pos5} 
                                            id={FormControl.pos5}
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="6th" 
                                            value={raceResult!.pos6} 
                                            id={FormControl.pos6}
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="7th" 
                                            value={raceResult!.pos7} 
                                            id={FormControl.pos7}
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="8th" 
                                            value={raceResult!.pos8} 
                                            id={FormControl.pos8}
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="9th" 
                                            value={raceResult!.pos9} 
                                            id={FormControl.pos9}
                                            onDriverChange={onDriverSelected}/>
                                        <DriverSelector 
                                            label="10th" 
                                            value={raceResult!.pos10} 
                                            id={FormControl.pos10}
                                            onDriverChange={onDriverSelected}/>
                        
                                        <DriverSelector 
                                            label="Fastest lap" 
                                            value={raceResult!.fastestLap} 
                                            id={FormControl.fastest} 
                                            onDriverChange={onDriverSelected}/>
                                        

                                        <Form.Group className="mb-3">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Has sprint qualifying" 
                                                checked={hasSprintQualifying}
                                                onChange={(event) => setHasSprintQualifying(event.target.checked)} 
                                            />
                                        </Form.Group>

                                        {hasSprintQualifying && (
                                            <>
                                                <Form.Group as={Row}>
                                                <Form.Label column sm={3} style={{fontWeight: 'bold'}}>
                                                    Sprint start
                                                </Form.Label>
                                                <Col>
                                                    <DatePicker 
                                                        selected={sprintQualifyingStart ? new Date(sprintQualifyingStart) : null} 
                                                        onChange={(newSprintStart) => setSprintQualifyingStart(newSprintStart as Date)}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={5}
                                                        timeCaption="time"
                                                        dateFormat="dd-MM-yyyy HH:mm"
                                                    />
                                                </Col>
                                                </Form.Group>

                                                <DriverSelector 
                                                    label="1st" 
                                                    value={sprintQualifying?.pos1 ?? null} 
                                                    id={FormControl.sqPos1} 
                                                    onDriverChange={onSprintQualifyingDriverSelected}/>
                                                <DriverSelector 
                                                    label="2nd" 
                                                    value={sprintQualifying?.pos2 ?? null} 
                                                    id={FormControl.sqPos2} 
                                                    onDriverChange={onSprintQualifyingDriverSelected}/>
                                                <DriverSelector 
                                                    label="3rd" 
                                                    value={sprintQualifying?.pos3 ?? null} 
                                                    id={FormControl.sqPos3}
                                                    onDriverChange={onSprintQualifyingDriverSelected}/>
                                            </>
                                        )}


                        
                                        <Row>
                                            <Col>
                                                { errorMessage ? <DisplayMessage message={errorMessage} variant={'danger'} /> : "" }
                                                { successMessage ? <DisplayMessage message={successMessage} variant={'success'} /> : "" }
                                            </Col>
                                        </Row>
                        
                                        <Row style={{ margin: "1rem 0 3rem 0" }}>
                                            <Button variant="outline-primary" onClick={goBackLink}>
                                                Go Back
                                            </Button>
                                            <Button variant="danger" onClick={submit}>
                                                Update Race
                                            </Button>
                                        </Row>
                                    </>
                                : <UnAuthorized /> 
                                }  
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}