import { ListGroup } from "react-bootstrap";
import { userStanding, userWithPools } from "../Models";
import styled from "styled-components";

export interface IProps {
  standings: userStanding[] | undefined;
  currentUser: userWithPools | undefined;
}

export const StandingsList = ({ standings, currentUser }: IProps) => {
  const StyledListGroup = styled(ListGroup)`
    font-family: "Formula1";
  `;

  const StyledListItem = styled(ListGroup.Item)<{
    highlight: boolean;
    position: number;
  }>`
    font-size: ${(props) => (props.position === 1 ? "15px" : "12px")};
    font-size: ${(props) => {
      switch (props.position) {
        case 1:
          return "17px";
        case 2:
          return "15px";
        case 3:
          return "14px";
        default:
          return "12px";
      }
    }};
    font-weight: ${(props) => (props.highlight ? "bold" : "normal")};
    display: grid;
    grid-template-columns: [position] 30px [username] auto [points] auto;
  `;

  const Position = styled.div`
    grid-area: "position";
  `;

  const Username = styled.div`
    grid-area: "username";
  `;

  const Points = styled.div`
    grid-area: "points";
    text-align: right;
  `;

  return (
    <StyledListGroup>
      {standings &&
        standings
          .sort((userA, userB) => userA.position - userB.position)
          .map((standing: userStanding, index) => {
            const isCurrentUser = standing.userId === currentUser?.userId;
            return (
              <StyledListItem
                highlight={isCurrentUser}
                position={standing.position}
                key={`item-${index}`}
              >
                <Position>{standing.position}</Position>
                <Username>{standing.name}</Username>
                <Points>{standing.totalPoints} pts</Points>
              </StyledListItem>
            );
          })}
    </StyledListGroup>
  );
};
