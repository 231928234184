import { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import {
  createPoolDto,
  createRace,
  Division,
  Driver,
  ExtendedDivision,
  pool,
  race,
  Team,
} from "../Models";
import { NavBar } from "../Components/NavBar";
import { Service } from "../Service";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

enum PageState {
  Neutral,
  Unauthorized,
  NotFound,
}

export const AdminDivisionPage = ({ match }: any) => {
  const {
    params: { divisionSlug },
  } = match;

  const [division, setDivision] = useState<Division>();
  const [pools, setPools] = useState<pool[]>();
  const [races, setRaces] = useState<race[]>();
  const [drivers, setDrivers] = useState<Driver[]>();
  const [teams, setTeams] = useState<Team[]>();
  const [pageState, setPageState] = useState<PageState>(PageState.Neutral);
  const [showCreatePoolModal, setShowCreatePoolModal] = useState(false);
  const [newPool, setNewPool] = useState<createPoolDto>();
  const [poolCreationFailureMessage, setPoolCreationFailureMessage] =
    useState<string>();
  const [showCreateRaceModal, setShowCreateRaceModal] = useState(false);
  const [createRaceFormData, setCreateRaceFormData] = useState<createRace>();
  const [createRaceErrorMessage, setCreateRaceErrorMessage] =
    useState<string>();

  useEffect(() => {
    loadData();
  }, [divisionSlug]);

  const loadData = () => {
    Service.getExtendedDivision(divisionSlug).then((response: Response) => {
      if (isUnautorized(response)) {
        setPageState(PageState.Unauthorized);
      } else if (response.status === 200) {
        response.json().then((extendedDivisionResponse: ExtendedDivision) => {
          setDivision(extendedDivisionResponse.division);
          setPools(extendedDivisionResponse.pools);
          setRaces(extendedDivisionResponse.races);
          setDrivers(extendedDivisionResponse.drivers);
          setTeams(extendedDivisionResponse.teams);
        });
      } else if (response.status === 404) {
        setPageState(PageState.NotFound);
      }
    });
  };

  const fetchNextRaceRound = () => {
    Service.getNextRaceRoundNumber(divisionSlug).then((nextRaceRoundNumber) => {
      setCreateRaceFormData({
        ...createRaceFormData,
        round: parseInt(nextRaceRoundNumber),
      } as createRace);
    });
  };

  const isUnautorized = (response: Response) => {
    return response.status === 401 || response.status === 403;
  };

  const handleCreateRaceClose = () => {
    setShowCreateRaceModal(false);
    setCreateRaceFormData(undefined);
  };

  const handleCreatePoolClose = () => {
    setShowCreatePoolModal(false);
    setNewPool(undefined);
    setPoolCreationFailureMessage(undefined);
  };

  const alphabetically = (a: string, b: string): number => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const AdminPageContent = () => {
    return (
      <>
        <h1>{division?.name}</h1>

        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <h3>Races</h3>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button variant="success" onClick={() => AddRaceLink()}>
              Add Race
            </Button>
          </Col>
        </Row>

        <Table striped bordered>
          <thead>
            <tr>
              <th>Round</th>
              <th>Race</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {races &&
              races
                .sort((raceA: race, raceB: race) => raceB.round - raceA.round)
                .map((race: race) => {
                  return <RaceRow key={`key-${race.round}`} race={race} />;
                })}
          </tbody>
        </Table>

        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <h3>Pools</h3>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button variant="success" onClick={() => addNewPool()}>
              Add Pool
            </Button>
          </Col>
        </Row>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Slug</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {pools &&
              pools.map((pool: pool) => {
                return <PoolRow key={`key-${pool.slug}`} pool={pool} />;
              })}
          </tbody>
        </Table>

        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <h3>Drivers</h3>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button variant="success" disabled onClick={() => AddRaceLink()}>
              Add Driver
            </Button>
          </Col>
        </Row>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Team</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {drivers &&
              drivers
                .sort((a: Driver, b: Driver) =>
                  alphabetically(a.team.name, b.team.name)
                )
                .map((driver: Driver) => {
                  return (
                    <DriverRow key={`key-${driver.driverId}`} driver={driver} />
                  );
                })}
          </tbody>
        </Table>

        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <h3>Teams</h3>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button variant="success" disabled onClick={() => AddRaceLink()}>
              Add Team
            </Button>
          </Col>
        </Row>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {teams &&
              teams.map((team: Team) => {
                return <TeamRow key={`key-${team.teamId}`} team={team} />;
              })}
          </tbody>
        </Table>
      </>
    );
  };

  const RaceRow = (props: { key: string; race: race }) => {
    return (
      <tr>
        <td>{props.race.round}</td>
        <td>
          <Flag code={props.race.countryCode} height={14} /> {props.race.name}
        </td>
        <td style={{ textAlign: "right" }}>
          <Button
            variant="outline-primary"
            onClick={() => EditRaceLink(props.race.round)}
          >
            Edit
          </Button>
        </td>
      </tr>
    );
  };

  const PoolRow = (props: { key: string; pool: pool }) => {
    return (
      <tr>
        <td>{props.pool.name}</td>
        <td>{props.pool.slug}</td>
        <td style={{ textAlign: "right" }}>
          <Button
            variant="outline-primary"
            onClick={() => ManagePoolLink(props.pool.slug)}
          >
            Manage
          </Button>
        </td>
      </tr>
    );
  };

  const DriverRow = (props: { key: string; driver: Driver }) => {
    return (
      <tr>
        <td>
          <StyledDriverNumber teamColor={props.driver.team.color}>
            {props.driver.number}
          </StyledDriverNumber>
          <Flag code={props.driver.countryCode} height={14} />{" "}
          {props.driver.firstname} {props.driver.lastname}{" "}
          {props.driver.isParticipating === false && (
            <Badge bg="secondary">Not Driving</Badge>
          )}
        </td>
        <td>
          <StyledTeamName teamColor={props.driver.team.color}>
            {props.driver.team.name}
          </StyledTeamName>
        </td>
        <td style={{ textAlign: "right" }}>
          <Button
            variant="outline-primary"
            onClick={() => viewDriver(props.driver.driverId)}
          >
            Edit
          </Button>
        </td>
      </tr>
    );
  };

  const TeamRow = (props: { key: string; team: Team }) => {
    return (
      <tr>
        <td>
          <StyledTeamName teamColor={props.team.color}>
            {props.team.fullName}
          </StyledTeamName>
        </td>
        <td style={{ textAlign: "right" }}>
          <Button
            variant="outline-primary"
            disabled
            onClick={() => viewTeam(props.team.teamId)}
          >
            Edit
          </Button>
        </td>
      </tr>
    );
  };

  const EditRaceLink = (round: number) => {
    window.location.href = `/Admin/Division/${divisionSlug}/EditRace/${round}`;
  };

  const ManagePoolLink = (poolSlug: string) => {
    window.location.href = `/Admin/Pool/${poolSlug}`;
  };

  const viewDriver = (driverId: string) => {
    // implement
  };

  const viewTeam = (teamId: string) => {
    // implement
  };

  const AddRaceLink = () => {
    setShowCreateRaceModal(true);
    fetchNextRaceRound();
  };

  const addNewPool = () => {
    setNewPool({ divisionId: division?.divisionId } as createPoolDto);
    setShowCreatePoolModal(true);
  };

  const handleNewRaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCreateRaceFormData({
      ...createRaceFormData,
      [name]: value,
    } as createRace);
  };

  const handleRaceStartChange = (raceStart: Date) => {
    setCreateRaceFormData({
      ...createRaceFormData,
      raceStart: raceStart,
    } as createRace);
  };

  const handleNewPoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewPool({ ...newPool, [name]: value } as createPoolDto);
  };

  const createNewRace = () => {
    if (createRaceFormData !== undefined) {
      Service.createRace(divisionSlug, createRaceFormData).then((response) => {
        if (response.status === 200) {
          loadData();
          handleCreateRaceClose();
        } else {
          response.text().then((responseBody) => {
            setCreateRaceErrorMessage(`${response.status} | ${responseBody}`);
          });
        }
      });
    }
  };

  const createNewPool = () => {
    if (newPool !== undefined) {
      Service.createPool(newPool).then((response: Response) => {
        if (response.status === 200) {
          response.json().then((poolCreationResult: pool) => {
            if (pools) {
              const allPools = pools;
              allPools.push(poolCreationResult);
              setPools(allPools);
              handleCreatePoolClose();
            }
          });
        } else {
          response.text().then((message) => {
            setPoolCreationFailureMessage(`${response.status}: ${message}`);
          });
        }
      });
    }
  };

  const UnAuthorized = () => {
    return (
      <>
        <h1>Unauthorized</h1>
        <p>
          <Link to="/overview">go back to home</Link>
        </p>
      </>
    );
  };

  const NotFoundMessage = () => {
    return (
      <>
        <h1>Not Found</h1>
        <p>
          <Link to="/overview">go back to home</Link>
        </p>
      </>
    );
  };

  const StyledTeamName = styled.span<{ teamColor: string }>`
    padding: 2px 8px;
    border-left: 4px solid ${(props) => props.teamColor};
  `;

  const StyledDriverNumber = styled.span<{ teamColor: string }>`
    font-weight: bold;
    font-style: italic;
    color: ${(props) => props.teamColor};
    padding-right: 10px;
    text-shadow: 1px 1px black;
  `;

  return (
    <>
      <NavBar />
      <Container fluid="md" style={{ marginTop: "50px" }}>
        <Row className="justify-content-md-center">
          <Col lg={7}>
            {pageState === PageState.Neutral && <AdminPageContent />}
            {pageState === PageState.Unauthorized && <UnAuthorized />}
            {pageState === PageState.NotFound && <NotFoundMessage />}
          </Col>
        </Row>
      </Container>

      <Modal show={showCreateRaceModal} onHide={handleCreateRaceClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Race</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Round</Form.Label>
              <Form.Control
                type="input"
                value={createRaceFormData?.round}
                onChange={handleNewRaceChange}
                name="round"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="input"
                value={createRaceFormData?.name}
                onChange={handleNewRaceChange}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="input"
                value={createRaceFormData?.fullName}
                onChange={handleNewRaceChange}
                name="fullName"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Short Name</Form.Label>
              <Form.Control
                type="input"
                value={createRaceFormData?.shortName}
                onChange={handleNewRaceChange}
                name="shortName"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="input"
                value={createRaceFormData?.country}
                onChange={handleNewRaceChange}
                name="country"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country Code</Form.Label>
              <Form.Control
                type="input"
                value={createRaceFormData?.countryCode}
                onChange={handleNewRaceChange}
                name="countryCode"
              />
            </Form.Group>
            <Flag code={createRaceFormData?.countryCode} height="28" />
            <Form.Group as={Row}>
              <Form.Label column sm={3} style={{ fontWeight: "bold" }}>
                Race start
              </Form.Label>
              <Col>
                <DatePicker
                  selected={
                    createRaceFormData?.raceStart
                      ? new Date(createRaceFormData.raceStart)
                      : null
                  }
                  onChange={(newRaceStart) =>
                    handleRaceStartChange(newRaceStart as Date)
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={10}
                  timeCaption="time"
                  dateFormat="dd-MM-yyyy HH:mm"
                  calendarStartDay={1}
                />
              </Col>
            </Form.Group>
          </Form>
          {createRaceErrorMessage && (
            <Alert variant={"danger"}>{createRaceErrorMessage}</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreateRaceClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={createNewRace}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCreatePoolModal} onHide={handleCreatePoolClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Pool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="input"
                value={newPool?.name}
                onChange={handleNewPoolChange}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Slug</Form.Label>
              <Form.Control
                type="input"
                value={newPool?.slug}
                onChange={handleNewPoolChange}
                name="slug"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Division</Form.Label>
              <Form.Control type="input" value={newPool?.divisionId} disabled />
            </Form.Group>
          </Form>
          {poolCreationFailureMessage && (
            <Alert variant={"danger"}>{poolCreationFailureMessage}</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCreatePoolClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={createNewPool}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
