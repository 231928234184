import { useEffect, useState } from "react";
import {
  race,
  raceParticipation,
  racePrediction,
  raceResultOverview,
} from "../Models";
import { DateTime } from "luxon";

export const useRaceWeekend = (
  races: race[] | undefined,
  predictions: racePrediction[] | undefined,
  participation: raceParticipation[] | undefined,
  raceResults: raceResultOverview[] | undefined
) => {
  const [raceWeekend, setRaceWeekend] = useState<race>();
  const [raceWeekendPrediction, setRaceWeekendPrediction] =
    useState<racePrediction>();
  const [raceWeekendParticipation, setRaceWeekendParticipation] =
    useState<raceParticipation>();
  const [finishedRaceResult, setFinishedRaceResult] =
    useState<raceResultOverview>();

  useEffect(() => {
    if (
      races !== undefined &&
      predictions !== undefined &&
      participation !== undefined &&
      raceResults !== undefined
    ) {
      const now = DateTime.now();
      const isThisWeekend = (race: race): boolean => {
        const raceStartDateTime: DateTime = DateTime.fromJSDate(
          new Date(race.raceStart)
        );
        const startOfWeekend = raceStartDateTime
          .minus({ days: 2 })
          .set({ hour: 0, minute: 0 });
        const endOfWeekend = raceStartDateTime
          .plus({ days: 3 })
          .set({ hour: 0, minute: 0 });
        return now >= startOfWeekend && now <= endOfWeekend;
      };

      const currentRaceWeekend = races.find((race: race) =>
        isThisWeekend(race)
      );

      if (currentRaceWeekend !== undefined) {
        setRaceWeekend(currentRaceWeekend);

        const findRaceWeekendPrediction = predictions.find(
          (prediction: racePrediction) =>
            prediction.round === currentRaceWeekend.round
        );
        setRaceWeekendPrediction(findRaceWeekendPrediction);

        const findRaceParticipation = participation.find(
          (participation: raceParticipation) =>
            participation.raceId === currentRaceWeekend.raceId
        );
        setRaceWeekendParticipation(findRaceParticipation);

        const isFinished = currentRaceWeekend.result !== undefined;
        if (isFinished) {
          const raceResult = raceResults.find(
            (raceResult: raceResultOverview) =>
              raceResult.round === currentRaceWeekend.round
          );
          if (raceResult !== undefined) {
            setFinishedRaceResult(raceResult);
          }
        }
      }
    }
  }, [races, predictions, participation, raceResults]);

  return [
    raceWeekend,
    raceWeekendPrediction,
    raceWeekendParticipation,
    finishedRaceResult,
  ] as const;
};
