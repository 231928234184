import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import settings from './settings';
import { AuthProviderProps, AuthProvider } from "oidc-react";

const oidcConfig: AuthProviderProps = {
  onSignIn: (userData) => {
    if (userData !== null) {
      localStorage.setItem('access_token', userData.access_token);
    }
    window.location.replace(window.location.toString().split(/[?#]/)[0]); // Remove authentication query part
  },
  authority: settings.authorizationServer,
  clientId: settings.clientId,
  redirectUri: window.location.toString(),
  responseType: "code",
  scope: settings.scope,
  postLogoutRedirectUri: settings.postLogoutRedirectUrl
};

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);