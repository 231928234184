import { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import {
  Driver,
  pool,
  poolOverview,
  predictionResult,
  PredictionVerdict,
  race,
  racePredictionResult,
  raceResult,
  raceResultOverview,
  sprintQualifyingPredictionResult,
  sprintQualifyingResult,
  userPointsBreakdownItem,
  userStanding,
} from "../Models";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { DateTime } from "luxon";
import { NavBar } from "../Components/NavBar";
import { Service } from "../Service";
import CountdownPill from "../Components/CountdownPill";
import { PageLoadingPlaceholder } from "../Components/PageLoadingPlaceholder";
import styled from "styled-components";
import Podium from "../Components/Podium";

enum PageState {
  Loading,
  Done,
  NotFound,
  Error,
}

export const OldPoolOverviewPage = ({ match }: any) => {
  const {
    params: { poolSlug },
  } = match;

  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const [errorMessage, setErrorMessage] = useState<string>("no error reported");

  const [pool, setPool] = useState<pool | null>(null);
  const [poolOverview, setPoolOverview] = useState<poolOverview | null>(null);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [races, setRaces] = useState<race[]>();
  const [raceWeekend, setRaceWeekend] = useState<race>();
  const [raceParticipants, setRaceParticipants] = useState<string[]>([]);

  useEffect(() => {
    Service.getPool(poolSlug).then((response: Response) => {
      if (response.ok) {
        response.json().then((pool: pool) => {
          setPool(pool);
        });
      } else if (response.status === 404) {
        setPageState(PageState.NotFound);
      }
    });
    return () => {};
  }, [poolSlug]);

  useEffect(() => {
    if (pool !== null) {
      Service.getDrivers(pool.divisionSlug).then((response: Response) => {
        if (response.ok) {
          response.json().then((driversResult: Driver[]) => {
            setDrivers(driversResult);
          });
        }
      });
      Service.fetchPoolOverview(poolSlug).then((response: Response) => {
        if (response.ok) {
          response.json().then((poolOverview: poolOverview) => {
            setPoolOverview(poolOverview);
            setPageState(PageState.Done);
          });
        } else if (response.status === 404) {
          setPageState(PageState.NotFound);
        } else if (response.status === 400) {
          response.text().then((bodyText: string) => {
            setErrorMessage(bodyText);
          });
          setPageState(PageState.Error);
        }
      });
      Service.fetchRaces(pool.divisionSlug).then((response) => {
        if (response.status === 200) {
          response.json().then((racesResponse: race[]) => {
            setRaces(racesResponse);
          });
        }
      });
    }
    return () => {};
  }, [pool]);

  useEffect(() => {
    if (races !== undefined) {
      const now = DateTime.now();
      const isThisWeekend = (race: race): boolean => {
        const raceStartDateTime: DateTime = DateTime.fromJSDate(
          new Date(race.raceStart)
        );
        const startOfWeekend = raceStartDateTime
          .minus({ days: 2 })
          .set({ hour: 0, minute: 0 });
        const endOfWeekend = raceStartDateTime
          .plus({ days: 1 })
          .set({ hour: 0, minute: 0 });
        return now >= startOfWeekend && now <= endOfWeekend;
      };

      const currentRaceWeekend = races.find((race: race) => {
        return race.result === null && isThisWeekend(race);
      });

      if (currentRaceWeekend !== undefined) {
        setRaceWeekend(currentRaceWeekend);

        Service.fetchRaceParticipants(poolSlug, currentRaceWeekend.round).then(
          (response) => {
            if (response.status === 200) {
              response.json().then((participants: string[]) => {
                setRaceParticipants(participants);
              });
            }
          }
        );
      }
    }
  }, [races]);

  const OverviewPage = () => {
    return (
      <>
        {pool?.isSeasonFinished ? (
          <SeasonFinishedSection />
        ) : (
          <>
            <Row className="justify-content-md-center">
              <Col lg={7}>
                {raceWeekend ? (
                  <RaceWeekendBanner />
                ) : (
                  <PoolRaceFinishOverview />
                )}
              </Col>
            </Row>
            {/* <div style={{ marginTop: 40 }}>
              <h2>Standings</h2>
              <Row>
                <Col lg={6}>
                  <StandingsTable />
                </Col>
              </Row>
              <Row>
                <Col>{poolOverview && <PointsGraph />}</Col>
              </Row>
              <Row>
                <Col>{poolOverview && <PositionGraph />}</Col>
              </Row>
            </div> */}
          </>
        )}
        <div style={{ marginTop: 40 }}>
          <h2>Race results</h2>
          <Row>
            <Col>
              {poolOverview &&
                poolOverview.raceResults
                  .sort((a, b) => b.round - a.round)
                  .map((race: raceResultOverview) => (
                    <RaceResultSection key={race.round} raceResult={race} />
                  ))}
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const SeasonFinishedSection = () => {
    const standings =
      poolOverview?.standing.sort(
        (userA, userB) => userA.position - userB.position
      ) ?? ([] as userStanding[]);
    const firstFinisher =
      typeof standings[0] === "undefined" ? undefined : standings[0];
    const secondFinisher =
      typeof standings[1] === "undefined" ? undefined : standings[1];
    const thirdFinsher =
      typeof standings[2] === "undefined" ? undefined : standings[2];

    const remainingStandings = standings.slice(3);

    return (
      <StyledSeasonFinshedSection>
        <Row>
          <Col>
            <Podium
              first={firstFinisher}
              second={secondFinisher}
              third={thirdFinsher}
            />
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Name</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {remainingStandings.map((entry: userStanding) => (
                  <tr key={`standings-row-${entry.position}`}>
                    <td>{entry.position}</td>
                    <td>{entry.name}</td>
                    <td>{entry.totalPoints}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 800, width: 1200 }}>
              {poolOverview && <PointsGraph />}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 800, width: 1200 }}>
              {poolOverview && <PositionGraph />}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <PoolRaceFinishOverview />
          </Col>
        </Row>
      </StyledSeasonFinshedSection>
    );
  };

  interface userPredictionRaceResult {
    finishPosition: number;
    name: string;
    pointsGained: number;
    standingsPosition: number;
  }

  const PoolRaceFinishOverview = () => {
    const mostRecentRace = races
      ?.filter((race) => race.result !== null)
      .sort((a, b) => b.round - a.round)[0];

    const mostRecentRaceResults: userPredictionRaceResult[] | undefined =
      poolOverview?.standing.map((userStanding: userStanding) => {
        const pointsBreakDownForRecentRace = userStanding.pointsBreakdown.find(
          (item) => item.round === mostRecentRace?.round
        );

        return {
          finishPosition: 0,
          name: userStanding.name,
          pointsGained: pointsBreakDownForRecentRace?.pointsGained ?? 0,
          standingsPosition: userStanding.position,
        };
      });

    const orderedUserRaceResults = mostRecentRaceResults?.sort(
      (a, b) => b.pointsGained - a.pointsGained
    );

    return (
      <StyledPoolRaceFinishOverview>
        <h3>Race Prediction Results</h3>
        <Table striped bordered size="sm">
          <thead>
            <tr>
              <th>Finished</th>
              <th>Name</th>
              <th>Points Gained</th>
              <th>Standings</th>
            </tr>
          </thead>
          <tbody>
            {orderedUserRaceResults &&
              orderedUserRaceResults.map(
                (entry: userPredictionRaceResult, index) => (
                  <tr key={`standings-row-${index}`}>
                    <td>{index + 1}</td>
                    <td>{entry.name}</td>
                    <td>
                      {entry.pointsGained >= 1 ? (
                        <span style={{ fontWeight: "bold", color: "#2caa1e" }}>
                          {entry.pointsGained} points
                        </span>
                      ) : (
                        <span>none</span>
                      )}
                    </td>
                    <th>{entry.standingsPosition}</th>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      </StyledPoolRaceFinishOverview>
    );
  };

  const PointsGraph = () => {
    const pointBreakdownSeries = poolOverview!.standing.map(
      (user: userStanding) => {
        return {
          name: user.name,
          data: user.pointsBreakdown.map(
            (breakDownItem: userPointsBreakdownItem) => {
              return {
                race: breakDownItem.race,
                points: breakDownItem.accumulativePoints,
              };
            }
          ),
        };
      }
    );

    return (
      <>
        {/* <ResponsiveContainer width="99%" height="99%"> */}
        <LineChart width={1200} height={800}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="race"
            type="category"
            allowDuplicatedCategory={false}
          />
          <YAxis dataKey="points" />
          <Tooltip />
          <Legend />
          {pointBreakdownSeries.map((serie, index) => (
            <Line
              type="monotone"
              strokeWidth={2}
              stroke={getStrokeColor(index)}
              dot={false}
              dataKey="points"
              data={serie.data}
              name={serie.name}
              key={`${serie.name}-${index}`}
            />
          ))}
        </LineChart>
        {/* </ResponsiveContainer> */}
      </>
    );
  };

  const PositionGraph = () => {
    const positionBreakdownSeries = poolOverview!.standing.map(
      (user: userStanding) => {
        return {
          name: user.name,
          data: user.pointsBreakdown.map(
            (breakDownItem: userPointsBreakdownItem) => {
              return {
                race: breakDownItem.race,
                position: breakDownItem.standingsPosition,
              };
            }
          ),
        };
      }
    );

    return (
      <>
        {/* <ResponsiveContainer width="99%" height="99%"> */}
        <LineChart width={1200} height={800}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="race"
            type="category"
            allowDuplicatedCategory={false}
          />
          <YAxis dataKey="position" reversed={true} />
          <Tooltip />
          <Legend />
          {positionBreakdownSeries.map((serie, index) => (
            <Line
              type="linear"
              strokeWidth={2}
              stroke={getStrokeColor(index)}
              dot={false}
              dataKey="position"
              data={serie.data}
              name={serie.name}
              key={`${serie.name}-${index}`}
            />
          ))}
        </LineChart>
        {/* </ResponsiveContainer> */}
      </>
    );
  };

  enum TeamColor {
    Mercedes = "#00D2BE",
    Ferrari = "#DC0000",
    RedBull = "#0600EF",
    Alpine = "#0090FF",
    Haas = "#CCC",
    AstonMartin = "#006F62",
    AlphaTauri = "#2B4562",
    McLaren = "#FF8700",
    AlfaRomeo = "#900000",
    Williams = "#005AFF",
  }

  const getStrokeColor = (index: number): string => {
    const teamColors: string[] = [
      TeamColor.Mercedes,
      TeamColor.RedBull,
      TeamColor.McLaren,
      TeamColor.Ferrari,
      TeamColor.AlphaTauri,
      TeamColor.AlfaRomeo,
      TeamColor.AstonMartin,
      TeamColor.Alpine,
      TeamColor.Haas,
      TeamColor.Williams,
    ];

    return teamColors[index % teamColors.length];
  };

  const StandingsTable = () => {
    return (
      <>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {poolOverview &&
              poolOverview.standing
                .sort((userA, userB) => userA.position - userB.position)
                .map((entry: userStanding) => (
                  <tr key={`standings-row-${entry.position}`}>
                    <td>{entry.position}</td>
                    <td>{entry.name}</td>
                    <td>{entry.totalPoints}</td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </>
    );
  };

  const RaceResultRow = (props: {
    key: string;
    positionLabel: string;
    driver: number | null;
    predictions: predictionResult[];
  }) => {
    return (
      <tr>
        <td>{props.positionLabel}</td>
        <td>
          <DriverDisplay driverNumber={props.driver} />
        </td>
        {props.predictions.map(
          (prediction: predictionResult, index: number) => {
            return <PredictionCell key={index} prediction={prediction} />;
          }
        )}
      </tr>
    );
  };

  const RaceResultTable = (props: {
    key: number;
    result: raceResult;
    predictions: racePredictionResult[];
  }) => {
    return (
      <Table striped bordered>
        <thead>
          <tr>
            <th>Position</th>
            <th>Race Result</th>
            {props.predictions.map(
              (prediction: racePredictionResult, index: number) => {
                return <th key={index}>{prediction.name}</th>;
              }
            )}
          </tr>
        </thead>
        <tbody>
          <RaceResultRow
            key="pos1"
            positionLabel="1st"
            driver={props.result.pos1}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos1;
              }
            )}
          />
          <RaceResultRow
            key="pos2"
            positionLabel="2nd"
            driver={props.result.pos2}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos2;
              }
            )}
          />
          <RaceResultRow
            key="pos3"
            positionLabel="3rd"
            driver={props.result.pos3}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos3;
              }
            )}
          />
          <RaceResultRow
            key="pos4"
            positionLabel="4th"
            driver={props.result.pos4}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos4;
              }
            )}
          />
          <RaceResultRow
            key="pos5"
            positionLabel="5th"
            driver={props.result.pos5}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos5;
              }
            )}
          />
          <RaceResultRow
            key="pos6"
            positionLabel="6th"
            driver={props.result.pos6}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos6;
              }
            )}
          />
          <RaceResultRow
            key="pos7"
            positionLabel="7th"
            driver={props.result.pos7}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos7;
              }
            )}
          />
          <RaceResultRow
            key="pos8"
            positionLabel="8th"
            driver={props.result.pos8}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos8;
              }
            )}
          />
          <RaceResultRow
            key="pos9"
            positionLabel="9th"
            driver={props.result.pos9}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos9;
              }
            )}
          />
          <RaceResultRow
            key="pos10"
            positionLabel="10th"
            driver={props.result.pos10}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.pos10;
              }
            )}
          />
          <RaceResultRow
            key="fastest"
            positionLabel="Fastest lap"
            driver={props.result.fastestLap}
            predictions={props.predictions.map(
              (racePrediction: racePredictionResult) => {
                return racePrediction.fastestLap;
              }
            )}
          />
        </tbody>
        <thead>
          <tr>
            <th>Points</th>
            <th></th>
            {props.predictions.map(
              (prediction: racePredictionResult, index: number) => {
                return <th key={index}>{prediction.totalPoints}</th>;
              }
            )}
          </tr>
        </thead>
      </Table>
    );
  };

  const SprintResultTable = (props: {
    key: number;
    result: sprintQualifyingResult;
    predictions: sprintQualifyingPredictionResult[];
  }) => {
    return (
      <Table striped bordered>
        <thead>
          <tr>
            <th>Position</th>
            <th>Sprint Result</th>
            {props.predictions.map(
              (prediction: sprintQualifyingPredictionResult, index: number) => {
                return <th key={index}>{prediction.name}</th>;
              }
            )}
          </tr>
        </thead>
        <tbody>
          <RaceResultRow
            key="pos1"
            positionLabel="1st"
            driver={props.result.pos1}
            predictions={props.predictions.map(
              (sprintPrediction: sprintQualifyingPredictionResult) => {
                return sprintPrediction.pos1;
              }
            )}
          />
          <RaceResultRow
            key="pos2"
            positionLabel="2nd"
            driver={props.result.pos2}
            predictions={props.predictions.map(
              (sprintPrediction: sprintQualifyingPredictionResult) => {
                return sprintPrediction.pos2;
              }
            )}
          />
          <RaceResultRow
            key="pos3"
            positionLabel="3rd"
            driver={props.result.pos3}
            predictions={props.predictions.map(
              (sprintPrediction: sprintQualifyingPredictionResult) => {
                return sprintPrediction.pos3;
              }
            )}
          />
        </tbody>
        <thead>
          <tr>
            <th>Points</th>
            <th></th>
            {props.predictions.map(
              (prediction: sprintQualifyingPredictionResult, index: number) => {
                return <th key={index}>{prediction.totalPoints}</th>;
              }
            )}
          </tr>
        </thead>
      </Table>
    );
  };

  const RaceResultSection = (props: {
    key: number;
    raceResult: raceResultOverview;
  }) => {
    return (
      <div style={{ marginTop: 40 }}>
        <h2>
          <Flag code={props.raceResult.countryCode} height="28" />{" "}
          {props.raceResult.raceName}
        </h2>
        {props.raceResult.result != null &&
          props.raceResult.predictions != null && (
            <RaceResultTable
              key={props.raceResult.round}
              result={props.raceResult.result}
              predictions={props.raceResult.predictions}
            />
          )}
        {props.raceResult.sprintQualifyingResult != null &&
          props.raceResult.sprintQualifyingPredictions != null && (
            <>
              <h5>Sprint Qualification</h5>
              <SprintResultTable
                key={props.raceResult.round}
                result={props.raceResult.sprintQualifyingResult}
                predictions={props.raceResult.sprintQualifyingPredictions}
              />
            </>
          )}
      </div>
    );
  };

  const PredictionCell = (props: {
    key: number;
    prediction: predictionResult;
  }) => {
    return (
      <td>
        {props.prediction.verdict === PredictionVerdict.Exact ? (
          <span style={{ fontWeight: "bold", color: "#2caa1e" }}>
            <DriverDisplay driverNumber={props.prediction.predicted} />
          </span>
        ) : (
          <span style={{ color: "#ccc" }}>
            <DriverDisplay driverNumber={props.prediction.predicted} />
          </span>
        )}
      </td>
    );
  };

  const DriverDisplay = (props: { driverNumber: number | null }) => {
    let name: string = "None";

    if (props.driverNumber !== null) {
      const driver = drivers.find(
        (x: Driver) => x.number === props.driverNumber
      );

      if (driver === undefined) {
        name = "Invalid";
      } else {
        name = driver.abbreviation!;
      }
    }
    return <span>{name}</span>;
  };

  const RaceWeekendBanner = () => {
    return (
      <>
        <Row>
          <Col>
            <Card
              style={{
                marginTop: 40,
                marginBottom: 40,
                backgroundImage:
                  "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url('/hungary.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "#FFF",
              }}
            >
              <Card.Header>
                <h3>
                  <Flag code={raceWeekend?.countryCode} height="30" />{" "}
                  {raceWeekend?.name}
                </h3>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                {raceWeekend?.sprintQualifying && (
                  <CountdownPill
                    date={raceWeekend?.sprintQualifying.start}
                    label="until sprint start"
                    variant="light"
                    completedText="Sprint has started"
                    completedVariant="danger"
                  />
                )}
                <CountdownPill
                  date={raceWeekend?.raceStart!}
                  label="until race start"
                  variant="danger"
                  completedText="Race has started"
                  completedVariant="danger"
                />
              </Card.Body>
              <Card.Footer style={{ textAlign: "center" }}>
                {raceWeekend?.sprintQualifying && (
                  <Link
                    to={`/SprintQualifyingPrediction/${poolSlug}/${raceWeekend?.round}`}
                    style={{ marginRight: 80 }}
                  >
                    <Button variant="primary">Predict Sprint Result</Button>
                  </Link>
                )}
                <Link to={`/RacePrediction/${poolSlug}/${raceWeekend?.round}`}>
                  <Button variant="danger">Predict Race Result</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <p>Predictions made by:</p>
            {raceParticipants.map((user) => {
              return (
                <Badge
                  key={`participant-${user}`}
                  pill
                  bg="danger"
                  style={{ margin: "0 5px" }}
                >
                  {user}
                </Badge>
              );
            })}
          </Col>
        </Row>
      </>
    );
  };

  const NotFoundMessage = () => {
    return (
      <Row className="justify-content-md-center">
        <Col lg={4} style={{ margin: "40px" }}>
          <h2>Pool not found</h2>
          <p>
            No pool with name "{poolSlug}" was found.{" "}
            <Link to="/">go back to home</Link>
          </p>
        </Col>
      </Row>
    );
  };

  const ErrorMessage = () => {
    return (
      <Row className="justify-content-md-center">
        <Col lg={4} style={{ margin: "40px" }}>
          <h2>Oops</h2>
          <p>{errorMessage}</p>
          <p>
            <Link to="/">go back to home</Link>
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {pageState === PageState.Loading ? (
        <PageLoadingPlaceholder />
      ) : (
        <>
          <NavBar />
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col>
                {pageState === PageState.Done && <OverviewPage />}
                {pageState === PageState.NotFound && <NotFoundMessage />}
                {pageState === PageState.Error && <ErrorMessage />}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

const StyledSeasonFinshedSection = styled.div`
    margin: 20px auto;
    max-width: 600px;
  `;

const StyledPoolRaceFinishOverview = styled.div`
  margin-top: 40px;
`;
