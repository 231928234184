import styled from "styled-components";
import { racePredictionResult, raceResultOverview } from "../Models";
import { useEffect, useState } from "react";

interface IProps {
  raceResult: raceResultOverview;
}

export const PodiumV2 = ({ raceResult }: IProps) => {
  const [first, setFirst] = useState<racePredictionResult>();
  const [second, setSecond] = useState<racePredictionResult>();
  const [third, setThird] = useState<racePredictionResult>();

  useEffect(() => {
    if (raceResult !== undefined) {
      const ranking = raceResult.predictions?.sort((a, b) =>
        a.totalPoints > b.totalPoints ? -1 : 1
      );
      if (ranking !== undefined) {
        setFirst(ranking[0]);
        setSecond(ranking[1]);
        setThird(ranking[2]);
      }
    }
  }, [raceResult]);

  const StyledPodium = styled.div`
    display: grid;
    grid-template-columns: [second] 1fr [first] 1fr [third] 1fr;
    margin: 15px 0;
    font-family: "Formula1";
  `;

  const StyledPodiumFinisher = styled.div`
    text-align: center;
    padding: 0 5px 3px 5px;
    font-weight: bold;
    font-size: 14px;
    color: #444;
  `;

  const StyledPodiumPoints = styled.div`
    text-align: center;
    padding: 0 5px 15px 5px;
    font-size: 12px;
  `;

  const StyledPodiumSecond = styled.div`
    display: grid;
    grid-area: "second";
    align-self: end;
  `;

  const StyledPodiumSecondStep = styled.div`
    display: grid;
    background-color: #fbfbfb;
    text-align: center;
    height: 60px;
    box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.3);
    border-radius: 5px 0 0 5px;
    align-content: center;
    font-size: 26px;
    font-weight: bold;
    color: #b4b8bf;
  `;

  const StyledPodiumFirst = styled.div`
    display: grid;
    grid-area: "first";
    align-self: end;
  `;

  const StyledPodiumFirstStep = styled.div`
    display: grid;
    background-color: #fff;
    text-align: center;
    height: 80px;
    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
    border-radius: 5px 5px 0 0;
    z-index: 20;
    align-content: center;
    font-size: 34px;
    font-weight: bold;
    color: #bfa145;
  `;

  const StyledPodiumThird = styled.div`
    display: grid;
    grid-area: "third";
    align-self: end;
  `;

  const StyledPodiumThirdStep = styled.div`
    display: grid;
    background-color: #fbfbfb;
    text-align: center;
    height: 40px;
    box-shadow: 0px 0px 5px rgb(0, 0, 0, 0.3);
    border-radius: 0 5px 5px 0;
    align-content: center;
    font-size: 18px;
    font-weight: bold;
    color: #594828;
  `;

  return (
    <StyledPodium>
      <StyledPodiumSecond>
        <StyledPodiumFinisher>
          {second?.name ? second?.name : "n/a"}
        </StyledPodiumFinisher>
        <StyledPodiumPoints>
          {second?.totalPoints ? second?.totalPoints + " points" : ""}
        </StyledPodiumPoints>
        <StyledPodiumSecondStep>2</StyledPodiumSecondStep>
      </StyledPodiumSecond>
      <StyledPodiumFirst>
        <StyledPodiumFinisher>
          {first?.name ? first?.name : "n/a"}
        </StyledPodiumFinisher>
        <StyledPodiumPoints>
          {first?.totalPoints ? first?.totalPoints + " points" : ""}
        </StyledPodiumPoints>
        <StyledPodiumFirstStep>1</StyledPodiumFirstStep>
      </StyledPodiumFirst>
      <StyledPodiumThird>
        <StyledPodiumFinisher>
          {third?.name ? third?.name : "n/a"}
        </StyledPodiumFinisher>
        <StyledPodiumPoints>
          {third?.totalPoints ? third?.totalPoints + " points" : ""}
        </StyledPodiumPoints>
        <StyledPodiumThirdStep>3</StyledPodiumThirdStep>
      </StyledPodiumThird>
    </StyledPodium>
  );
};

export default PodiumV2;
