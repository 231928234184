import { useState, useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { poolOverview, userPointsBreakdownItem, userStanding } from "../Models";
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { NavBar } from "../Components/NavBar";
import { Service } from "../Service";
import { PageLoadingPlaceholder } from "../Components/PageLoadingPlaceholder";
import styled from "styled-components";

enum PageState {
  Loading,
  Done,
  NotFound,
  Error,
}

export const PointPreviewPage = ({ match }: any) => {
  const {
    params: { poolSlug },
  } = match;

  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const [errorMessage, setErrorMessage] = useState<string>("no error reported");

  const [pool, setPool] = useState<poolOverview | null>(null);

  useEffect(() => {
    Service.fetchPoolOverview(poolSlug).then((response: Response) => {
      if (response.ok) {
        response.json().then((poolOverview: poolOverview) => {
          setPool(poolOverview);
          setPageState(PageState.Done);
        });
      } else if (response.status === 404) {
        setPageState(PageState.NotFound);
      } else if (response.status === 400) {
        response.text().then((bodyText: string) => {
          setErrorMessage(bodyText);
        });
        setPageState(PageState.Error);
      }
    });
    return () => {};
  }, [poolSlug]);

  useEffect(() => {}, []);

  const OverviewPage = () => {
    return (
      <>
        <SeasonFinishedSection />
      </>
    );
  };

  const SeasonFinishedSection = () => {
    const standings =
      pool?.standing.sort((userA, userB) => userA.position - userB.position) ??
      ([] as userStanding[]);
    const firstFinisher =
      typeof standings[0] === "undefined" ? undefined : standings[0];
    const secondFinisher =
      typeof standings[1] === "undefined" ? undefined : standings[1];
    const thirdFinsher =
      typeof standings[2] === "undefined" ? undefined : standings[2];

    const remainingStandings = standings.slice(3);

    return (
      <StyledSeasonFinshedSection>
        <Row>
          <Col>
            <StyledPodium>
              <StyledPodiumSecond>
                <StyledPodiumFinisher>
                  {secondFinisher?.name}
                </StyledPodiumFinisher>
                <StyledPodiumPoints>
                  {secondFinisher?.totalPoints} points
                </StyledPodiumPoints>
                <StyledPodiumSecondStep>2</StyledPodiumSecondStep>
              </StyledPodiumSecond>
              <StyledPodiumFirst>
                <StyledPodiumFinisher>
                  {firstFinisher?.name}
                </StyledPodiumFinisher>
                <StyledPodiumPoints>
                  {firstFinisher?.totalPoints} points
                </StyledPodiumPoints>
                <StyledPodiumFirstStep>1</StyledPodiumFirstStep>
              </StyledPodiumFirst>
              <StyledPodiumThird>
                <StyledPodiumFinisher>
                  {thirdFinsher?.name}
                </StyledPodiumFinisher>
                <StyledPodiumPoints>
                  {thirdFinsher?.totalPoints} points
                </StyledPodiumPoints>
                <StyledPodiumThirdStep>3</StyledPodiumThirdStep>
              </StyledPodiumThird>
            </StyledPodium>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Name</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {remainingStandings.map((entry: userStanding) => (
                  <tr key={`standings-row-${entry.position}`}>
                    <td>{entry.position}</td>
                    <td>{entry.name}</td>
                    <td>{entry.totalPoints}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 800, width: 1200 }}>
              {pool && <PointsGraph />}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ height: 800, width: 1200 }}>
              {pool && <PositionGraph />}
            </div>
          </Col>
        </Row>
      </StyledSeasonFinshedSection>
    );
  };

  const StyledSeasonFinshedSection = styled.div`
    margin: 20px auto;
    max-width: 600px;
  `;

  const StyledPodium = styled.div`
    display: grid;
    grid-template-columns: [second] auto [first] auto [third] auto;
    margin: 20px 0;
  `;

  const StyledPodiumFinisher = styled.div`
    text-align: center;
    padding: 0 5px 3px 5px;
    font-weight: bold;
    font-size: 20px;
  `;

  const StyledPodiumPoints = styled.div`
    text-align: center;
    padding: 0 5px 25px 5px;
    font-size: 18px;
  `;

  const StyledPodiumSecond = styled.div`
    display: grid;
    grid-area: "second";
    align-self: end;
  `;

  const StyledPodiumSecondStep = styled.div`
    display: grid;
    background-color: #fff;
    text-align: center;
    height: 60px;
    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
    border-radius: 5px 0 0 5px;
    align-content: center;
    font-size: 30px;
    font-weight: bold;
    color: #b4b8bf;
  `;

  const StyledPodiumFirst = styled.div`
    display: grid;
    grid-area: "first";
    align-self: end;
  `;

  const StyledPodiumFirstStep = styled.div`
    display: grid;
    background-color: #fff;
    text-align: center;
    height: 80px;
    box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.3);
    border-radius: 5px 5px 0 0;
    z-index: 20;
    align-content: center;
    font-size: 40px;
    font-weight: bold;
    color: #bfa145;
  `;

  const StyledPodiumThird = styled.div`
    display: grid;
    grid-area: "third";
    align-self: end;
  `;

  const StyledPodiumThirdStep = styled.div`
    display: grid;
    background-color: #fff;
    text-align: center;
    height: 40px;
    box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.3);
    border-radius: 0 5px 5px 0;
    align-content: center;
    font-size: 24px;
    font-weight: bold;
    color: #594828;
  `;

  const PointsGraph = () => {
    const pointBreakdownSeries = pool!.standing.map((user: userStanding) => {
      return {
        name: user.name,
        data: user.pointsBreakdown.map(
          (breakDownItem: userPointsBreakdownItem) => {
            return {
              race: breakDownItem.race,
              points: breakDownItem.accumulativePoints,
            };
          }
        ),
      };
    });

    return (
      <>
        {/* <ResponsiveContainer width="99%" height="99%"> */}
        <LineChart width={1200} height={800}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="race"
            type="category"
            allowDuplicatedCategory={false}
          />
          <YAxis dataKey="points" />
          <Tooltip />
          <Legend />
          {pointBreakdownSeries.map((serie, index) => (
            <Line
              type="monotone"
              strokeWidth={2}
              stroke={getStrokeColor(index)}
              dot={false}
              dataKey="points"
              data={serie.data}
              name={serie.name}
              key={`${serie.name}-${index}`}
            />
          ))}
        </LineChart>
        {/* </ResponsiveContainer> */}
      </>
    );
  };

  const PositionGraph = () => {
    const positionBreakdownSeries = pool!.standing.map((user: userStanding) => {
      return {
        name: user.name,
        data: user.pointsBreakdown.map(
          (breakDownItem: userPointsBreakdownItem) => {
            return {
              race: breakDownItem.race,
              position: breakDownItem.standingsPosition,
            };
          }
        ),
      };
    });

    return (
      <>
        {/* <ResponsiveContainer width="99%" height="99%"> */}
        <LineChart width={1200} height={800}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="race"
            type="category"
            allowDuplicatedCategory={false}
          />
          <YAxis dataKey="position" reversed={true} />
          <Tooltip />
          <Legend />
          {positionBreakdownSeries.map((serie, index) => (
            <Line
              type="linear"
              strokeWidth={2}
              stroke={getStrokeColor(index)}
              dot={false}
              dataKey="position"
              data={serie.data}
              name={serie.name}
              key={`${serie.name}-${index}`}
            />
          ))}
        </LineChart>
        {/* </ResponsiveContainer> */}
      </>
    );
  };

  enum TeamColor {
    Mercedes = "#00D2BE",
    Ferrari = "#DC0000",
    RedBull = "#0600EF",
    Alpine = "#0090FF",
    Haas = "#CCC",
    AstonMartin = "#006F62",
    AlphaTauri = "#2B4562",
    McLaren = "#FF8700",
    AlfaRomeo = "#900000",
    Williams = "#005AFF",
  }

  const getStrokeColor = (index: number): string => {
    const teamColors: string[] = [
      TeamColor.Mercedes,
      TeamColor.RedBull,
      TeamColor.McLaren,
      TeamColor.Ferrari,
      TeamColor.AlphaTauri,
      TeamColor.AlfaRomeo,
      TeamColor.AstonMartin,
      TeamColor.Alpine,
      TeamColor.Haas,
      TeamColor.Williams,
    ];

    return teamColors[index % teamColors.length];
  };

  const NotFoundMessage = () => {
    return (
      <Row className="justify-content-md-center">
        <Col lg={4} style={{ margin: "40px" }}>
          <h2>Pool not found</h2>
          <p>
            No pool with name "{poolSlug}" was found.{" "}
            <Link to="/">go back to home</Link>
          </p>
        </Col>
      </Row>
    );
  };

  const ErrorMessage = () => {
    return (
      <Row className="justify-content-md-center">
        <Col lg={4} style={{ margin: "40px" }}>
          <h2>Oops</h2>
          <p>{errorMessage}</p>
          <p>
            <Link to="/">go back to home</Link>
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {pageState === PageState.Loading ? (
        <PageLoadingPlaceholder />
      ) : (
        <>
          <NavBar />
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col>
                {pageState === PageState.Done && <OverviewPage />}
                {pageState === PageState.NotFound && <NotFoundMessage />}
                {pageState == PageState.Error && <ErrorMessage />}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};
