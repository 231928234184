import { Spinner } from "react-bootstrap";
import styled from "styled-components";

export const PageLoadingPlaceholder2 = () => {
  return (
    <div>
      <StyledLoadingWrapper>
        <Spinner animation="border" variant="danger" />
      </StyledLoadingWrapper>
    </div>
  );
};

const StyledLoadingWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;
