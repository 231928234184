import { useAuth } from "oidc-react";
import { Badge, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { pool, userWithPools } from "../Models";
import styled from "styled-components";

export interface INavBarProps {
  currentPool?: pool;
  user?: userWithPools;
}

export const NavBar2 = ({ user, currentPool }: INavBarProps) => {
  const auth = useAuth();

  const signOut = () => {
    auth.signOutRedirect();
  };

  const adminLink = () => {
    window.location.href = "/admin";
  };

  const goToPool = (pool: pool) => {
    window.location.href = `/pool/${pool.slug}`;
  };

  const PoolSelector = () => {
    return (
      <>
        {user && (
          <Nav>
            <NavDropdown
              title={currentPool?.name.replace("F1", "")}
              id="collapsible-nav-dropdown"
              align="start"
              menuVariant="light"
            >
              {user.pools.map((pool: pool) => {
                const isCurrentPool = currentPool?.poolId === pool.poolId;
                return (
                  <NavDropdown.Item
                    onClick={() => goToPool(pool)}
                    active={isCurrentPool}
                  >
                    {pool.name}{" "}
                    <Badge bg="secondary">{pool.divisionSlug}</Badge>{" "}
                    {pool.isSeasonFinished && (
                      <Badge bg="dark">Season Ended</Badge>
                    )}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          </Nav>
        )}
      </>
    );
  };

  const LogoContainer = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    padding: 0;
    margin: 0;
    height: 34px;
  `;

  const LogoRow = styled.div`
    font-family: "Formula1";
    font-weight: bold;
    color: #000;
    line-height: 100%;
    font-size: 16px;
  `;

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      style={{ fontFamily: "Formula1" }}
    >
      <Container>
        <Navbar.Brand>
          <LogoContainer>
            <LogoRow>BOX</LogoRow>
            <LogoRow>BOX</LogoRow>
          </LogoContainer>
        </Navbar.Brand>
        <PoolSelector />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <NavDropdown
              title={user?.name}
              id="collapsible-nav-dropdown"
              align="end"
              menuVariant="light"
            >
              <NavDropdown.ItemText>{user?.email}</NavDropdown.ItemText>
              {user?.isAdmin && (
                <NavDropdown.Item onClick={() => adminLink()}>
                  Admin
                </NavDropdown.Item>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => signOut()}>
                Log Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
