export interface user {
  userId: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
  sub: string;
  isAdmin: boolean;
}

export interface slimUserDto {
  userId: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
}

export interface Division {
  divisionId: string;
  name: string;
  slug: string;
  season: number;
  isSeasonFinished: boolean;
}

export interface ExtendedDivision {
  division: Division;
  pools: pool[];
  races: race[];
  drivers: Driver[];
  teams: Team[];
}

export interface pool {
  poolId: string;
  name: string;
  shortName: string;
  slug: string;
  divisionSlug: string;
  isPrivate: boolean;
  isSeasonFinished: boolean;
}

export interface createPoolDto {
  name: string;
  slug: string;
  divisionId: string;
}

export interface userWithPools {
  userId: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
  sub: string;
  isAdmin: boolean;
  pools: pool[];
}

export interface race {
  raceId: string;
  division: Division;
  round: number;
  name: string;
  fullName: string;
  shortName: string;
  country: string;
  countryCode: string;
  raceStart: Date;
  startingGrid: startingGrid | null;
  result: raceResult | null;
  sprintQualifying: sprintQualifying | null;
}

export interface startingGrid {
  pos1: number | null;
  pos2: number | null;
  pos3: number | null;
  pos4: number | null;
  pos5: number | null;
  pos6: number | null;
  pos7: number | null;
  pos8: number | null;
  pos9: number | null;
  pos10: number | null;
  pos11: number | null;
  pos12: number | null;
  pos13: number | null;
  pos14: number | null;
  pos15: number | null;
  pos16: number | null;
  pos17: number | null;
  pos18: number | null;
  pos19: number | null;
  pos20: number | null;
}

export interface sprintQualifying {
  start: Date;
  pos1: number | null;
  pos2: number | null;
  pos3: number | null;
}

export interface createRace {
  round: number;
  name: string;
  fullName: string;
  shortName: string;
  country: string;
  countryCode: string;
  raceStart: Date;
}

export enum PredictionPosition {
  pos1 = "pos1",
  pos2 = "pos2",
  pos3 = "pos3",
  pos4 = "pos4",
  pos5 = "pos5",
  fastestLap = "fastestLap",
}

export interface racePrediction2 {
  pos1: number | undefined;
  pos2: number | undefined;
  pos3: number | undefined;
  pos4: number | undefined;
  pos5: number | undefined;
  fastestLap: number | undefined;
}

export interface racePrediction {
  round: number;
  pos1: number | null;
  pos2: number | null;
  pos3: number | null;
  pos4: number | null;
  pos5: number | null;
  pos6: number | null;
  pos7: number | null;
  pos8: number | null;
  pos9: number | null;
  pos10: number | null;
  fastestLap: number | null;
}

export interface sprintQualifyingPrediction {
  id: string | null;
  round: number;
  pos1: number | null;
  pos2: number | null;
  pos3: number | null;
}

export interface raceResult {
  pos1: number | null;
  pos2: number | null;
  pos3: number | null;
  pos4: number | null;
  pos5: number | null;
  pos6: number | null;
  pos7: number | null;
  pos8: number | null;
  pos9: number | null;
  pos10: number | null;
  fastestLap: number | null;
}

export interface Team {
  teamId: string;
  name: string;
  fullName: string;
  color: string;
}

export interface Driver {
  driverId: string;
  number: number;
  abbreviation: string;
  firstname: string;
  lastname: string;
  country: string;
  countryCode: string;
  team: Team;
  isParticipating: boolean;
}

export interface predictionResult {
  predicted: number | null;
  points: number;
  verdict: PredictionVerdict;
}

export enum PredictionVerdict {
  Exact = "Exact",
  Near = "Near",
  Wrong = "Wrong",
}

export interface racePredictionResult {
  name: string;
  pos1: predictionResult;
  pos2: predictionResult;
  pos3: predictionResult;
  pos4: predictionResult;
  pos5: predictionResult;
  pos6: predictionResult;
  pos7: predictionResult;
  pos8: predictionResult;
  pos9: predictionResult;
  pos10: predictionResult;
  fastestLap: predictionResult;
  totalPoints: number;
}

export interface sprintQualifyingResult {
  pos1: number | null;
  pos2: number | null;
  pos3: number | null;
}

export interface sprintQualifyingPredictionResult {
  name: string;
  pos1: predictionResult;
  pos2: predictionResult;
  pos3: predictionResult;
  totalPoints: number;
}

export interface raceResultOverview {
  raceName: string;
  round: number;
  countryCode: string;
  result?: raceResult;
  predictions?: racePredictionResult[];
  sprintQualifyingResult?: sprintQualifyingResult;
  sprintQualifyingPredictions?: sprintQualifyingPredictionResult[];
}

export interface raceParticipation {
  poolId: string;
  raceId: string;
  participatingUsers: string[];
}

export interface poolOverview {
  user: userWithPools;
  pool: pool;
  drivers: Driver[];
  races: race[];
  standing: userStanding[];
  raceResults: raceResultOverview[];
  racePredictions: racePrediction[];
  raceParticipation: raceParticipation[];
}

export interface userStanding {
  position: number;
  name: string;
  userId: string;
  totalPoints: number;
  pointsBreakdown: userPointsBreakdownItem[];
}

export interface userPointsBreakdownItem {
  round: number;
  race: string;
  pointsGained: number;
  accumulativePoints: number;
  standingsPosition: number;
  didNotPredict: boolean;
}

export interface liveRaceEvent {
  raceId: string;
  raceName: string;
  countryCode: string;
  sequence: number;
  state: string;
  lap: number;
  preliminaryRaceResult: raceResultOverview;
}
