import { ListGroup, Modal } from "react-bootstrap";
import Flag from "react-world-flags";
import styled from "styled-components";
import { race } from "../Models";
import { DateTime } from "luxon";

export interface IProps {
  races: race[] | undefined;
  show: boolean;
  onHide: () => void;
}

export const RaceCalendarModal = ({ races, show, onHide }: IProps) => {
  const StyledModal = styled(Modal)`
    font-family: "Formula1";
  `;

  const StyledListGroup = styled(ListGroup)`
    font-family: "Formula1";
  `;

  const StyledListItem = styled(ListGroup.Item)<{
    highlight: boolean;
  }>`
    font-size: 12px;
    font-weight: ${(props) => (props.highlight ? "bold" : "normal")};
    display: grid;
    grid-template-columns: [position] 40px [username] auto [points] auto;
  `;

  const Position = styled.div`
    grid-area: "position";
  `;

  const Username = styled.div`
    grid-area: "username";
  `;

  const Points = styled.div`
    grid-area: "points";
    text-align: right;
  `;

  return (
    <StyledModal show={show} fullscreen={"sm-down"} onHide={() => onHide()}>
      <Modal.Header closeButton>
        <Modal.Title>Race Calendar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StyledListGroup>
          {races &&
            races.map((race: race, index) => {
              return (
                <StyledListItem
                  highlight={false}
                  key={`item-${index}`}
                  disabled={race.result !== null}
                >
                  <Position>
                    <Flag code={race.countryCode} height={12} />
                  </Position>
                  <Username>{race.country}</Username>
                  <Points>
                    {DateTime.fromJSDate(new Date(race.raceStart)).toFormat(
                      "d MMM HH:mm"
                    )}
                  </Points>
                </StyledListItem>
              );
            })}
        </StyledListGroup>
      </Modal.Body>
    </StyledModal>
  );
};
