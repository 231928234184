const dev = {
    clientId: "boxbox-web-local",
    f1PoolBaseUri: "http://localhost:5000",
    authorizationServer: "http://localhost:5010",
    redirectUrl: "http://localhost:3000",
    scope: "openid profile email boxbox-api offline_access",
    extra: {prompt: 'consent', access_type: 'offline'},
    postLogoutRedirectUrl: "http://localhost:3000"
}

const prod = {
    clientId: "boxbox-web",
    f1PoolBaseUri: "https://api.boxboxapp.com",
    authorizationServer: "https://auth.boxboxapp.com",
    redirectUrl: "https://boxboxapp.com",
    scope: "openid profile email boxbox-api offline_access",
    extra: {prompt: 'consent', access_type: 'offline'},
    postLogoutRedirectUrl: "https://boxboxapp.com"
}

const config = process.env.NODE_ENV === 'production'
    ? prod
    : dev;

export default {
    ...config
}