import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { Badge, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Constants } from "../Constants";
import { pool, user, userWithPools } from "../Models";
import { Service } from "../Service";

export const NavBar = () => {
  const [userInfo, setUserInfo] = useState<userWithPools>();

  const auth = useAuth();

  useEffect(() => {
    Service.fetchUserInfo().then((response) => {
      if (response.status === 200) {
        response.json().then((userInfo: userWithPools) => {
          setUserInfo(userInfo);
        });
      }
    });
    return () => {};
  }, []);

  const signOut = () => {
    auth.signOutRedirect();
  };

  const adminLink = () => {
    window.location.href = "/admin";
  };

  const goToPool = (pool: pool) => {
    window.location.href = `/overview/${pool.slug}`;
  };

  const PoolSelector = () => {
    return (
      <>
        {userInfo && (
          <Nav>
            <NavDropdown
              title="Pools"
              id="collapsible-nav-dropdown"
              align="end"
              menuVariant="dark"
            >
              {userInfo.pools.map((pool: pool) => {
                return (
                  <NavDropdown.Item onClick={() => goToPool(pool)}>
                    {pool.name}{" "}
                    <Badge bg="secondary">{pool.divisionSlug}</Badge>{" "}
                    {pool.isSeasonFinished && (
                      <Badge bg="dark">Season Ended</Badge>
                    )}
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          </Nav>
        )}
      </>
    );
  };

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>F1 Pool</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href={Constants.Home}>Overview</Nav.Link>
          </Nav>
          <PoolSelector />
          <Nav>
            <NavDropdown
              title={userInfo?.name}
              id="collapsible-nav-dropdown"
              align="end"
              menuVariant="dark"
            >
              <NavDropdown.ItemText>{userInfo?.email}</NavDropdown.ItemText>
              {userInfo?.isAdmin && (
                <NavDropdown.Item onClick={() => adminLink()}>
                  Admin
                </NavDropdown.Item>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => signOut()}>
                Log Out
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
