import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { NavBar } from "../Components/NavBar";
import { PageLoadingPlaceholder } from "../Components/PageLoadingPlaceholder";
import { pool, userWithPools } from "../Models";
import { Service } from "../Service";

enum PageState {
  Loading,
  NoPoolsFound,
  UserNotFound,
  Error,
}

export const Home = () => {
  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const [userInfo, setUserInfo] = useState<userWithPools>();

  useEffect(() => {
    Service.fetchUserInfo().then((response: Response) => {
      if (response.status === 200) {
        response.json().then((userInfo: userWithPools) => {
          setUserInfo(userInfo);
        });
      } else if (response.status === 404) {
        setPageState(PageState.UserNotFound);
      } else {
        setPageState(PageState.Error);
      }
    });
  }, []);

  useEffect(() => {
    if (userInfo !== undefined) {
      const pools = userInfo.pools;
      const poolsFromOldSeasons = pools.filter(
        (pool: pool) => pool.isSeasonFinished
      );
      const poolsFromCurrentSeason = pools.filter(
        (pool: pool) => !pool.isSeasonFinished
      );

      if (poolsFromCurrentSeason.length >= 1) {
        redirectToPool(poolsFromCurrentSeason.at(0));
      } else if (poolsFromOldSeasons.length >= 1) {
        redirectToPool(poolsFromOldSeasons.at(0));
      } else {
        setPageState(PageState.NoPoolsFound);
      }
    }
  }, [userInfo]);

  const redirectToPool = (pool: pool | undefined) => {
    if (pool) {
      window.location.replace(`/pool/${pool.slug}`);
    }
  };

  const NoPoolsFoundMessage = () => {
    return (
      <Card style={{ marginTop: "3rem" }}>
        <Card.Header>No Pools Found</Card.Header>
        <Card.Body>This user is not a member of any pools (yet)</Card.Body>
      </Card>
    );
  };

  const UserNotFoundMessage = () => {
    return (
      <Card style={{ marginTop: "3rem" }}>
        <Card.Header>User not found</Card.Header>
        <Card.Body>There is no current user session.</Card.Body>
      </Card>
    );
  };

  const ErrorMessage = () => {
    return (
      <Card style={{ marginTop: "3rem" }}>
        <Card.Header>Error</Card.Header>
        <Card.Body>Something went wrong</Card.Body>
      </Card>
    );
  };

  return (
    <div>
      {pageState !== PageState.Loading && (
        <>
          <NavBar />
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col>
                {pageState === PageState.UserNotFound && (
                  <UserNotFoundMessage />
                )}
                {pageState === PageState.NoPoolsFound && (
                  <NoPoolsFoundMessage />
                )}
                {pageState === PageState.Error && <ErrorMessage />}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};
