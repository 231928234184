import { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import {
  Driver,
  pool,
  poolOverview,
  predictionResult,
  PredictionVerdict,
  race,
  racePredictionResult,
  raceResult,
  raceResultOverview,
  userStanding,
} from "../Models";
import { DateTime } from "luxon";
import { NavBar } from "../Components/NavBar";
import { Service } from "../Service";
import CountdownPill from "../Components/CountdownPill";
import { PageLoadingPlaceholder } from "../Components/PageLoadingPlaceholder";
import styled from "styled-components";
import Podium from "../Components/Podium";

enum PageState {
  Loading,
  Done,
  NotFound,
  Error,
}

export const PoolOverviewPage = ({ match }: any) => {
  const {
    params: { poolSlug },
  } = match;

  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const [errorMessage, setErrorMessage] = useState<string>("no error reported");

  const [pool, setPool] = useState<pool | null>(null);
  const [poolOverview, setPoolOverview] = useState<poolOverview | null>(null);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  const [races, setRaces] = useState<race[]>();
  const [raceWeekend, setRaceWeekend] = useState<race>();
  const [raceParticipants, setRaceParticipants] = useState<string[]>([]);

  useEffect(() => {
    Service.getPool(poolSlug).then((response: Response) => {
      if (response.ok) {
        response.json().then((pool: pool) => {
          setPool(pool);
        });
      } else if (response.status === 404) {
        setPageState(PageState.NotFound);
      }
    });
    return () => {};
  }, [poolSlug]);

  useEffect(() => {
    if (pool !== null) {
      Service.getDrivers(pool.divisionSlug).then((response: Response) => {
        if (response.ok) {
          response.json().then((driversResult: Driver[]) => {
            setDrivers(driversResult);
          });
        }
      });
      Service.fetchPoolOverview(poolSlug).then((response: Response) => {
        if (response.ok) {
          response.json().then((poolOverview: poolOverview) => {
            setPoolOverview(poolOverview);
            setPageState(PageState.Done);
          });
        } else if (response.status === 404) {
          setPageState(PageState.NotFound);
        } else if (response.status === 400) {
          response.text().then((bodyText: string) => {
            setErrorMessage(bodyText);
          });
          setPageState(PageState.Error);
        }
      });
      Service.fetchRaces(pool.divisionSlug).then((response) => {
        if (response.status === 200) {
          response.json().then((racesResponse: race[]) => {
            setRaces(racesResponse);
          });
        }
      });
    }
    return () => {};
  }, [pool, poolSlug]);

  useEffect(() => {
    if (races !== undefined) {
      const now = DateTime.now();
      const isThisWeekend = (race: race): boolean => {
        const raceStartDateTime: DateTime = DateTime.fromJSDate(
          new Date(race.raceStart)
        );
        const startOfWeekend = raceStartDateTime
          .minus({ days: 2 })
          .set({ hour: 0, minute: 0 });
        const endOfWeekend = raceStartDateTime
          .plus({ days: 1 })
          .set({ hour: 0, minute: 0 });
        return now >= startOfWeekend && now <= endOfWeekend;
      };

      const currentRaceWeekend = races.find((race: race) => {
        return race.result === null && isThisWeekend(race);
      });

      if (currentRaceWeekend !== undefined) {
        setRaceWeekend(currentRaceWeekend);

        Service.fetchRaceParticipants(poolSlug, currentRaceWeekend.round).then(
          (response) => {
            if (response.status === 200) {
              response.json().then((participants: string[]) => {
                setRaceParticipants(participants);
              });
            }
          }
        );
      }
    }
  }, [races, poolSlug]);

  const OverviewPage = () => {
    return (
      <>
        {pool?.isSeasonFinished ? (
          <SeasonFinishedSection />
        ) : (
          <>
            <Row className="justify-content-md-center">
              <Col lg={7}>
                {raceWeekend ? (
                  <RaceWeekendBanner />
                ) : (
                  <PoolRaceFinishOverview />
                )}
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col lg={7}>
                <div style={{ marginTop: 40 }}>
                  <h2>Standings</h2>
                  <Row>
                    <Col lg={6}>
                      <StandingsTable />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
        <div style={{ marginTop: 40 }}>
          <h2>Race results</h2>
          <Row>
            <Col>
              {poolOverview &&
                poolOverview.raceResults
                  .sort((a, b) => b.round - a.round)
                  .map((race: raceResultOverview) => (
                    <RaceResultSection key={race.round} raceResult={race} />
                  ))}
            </Col>
          </Row>
        </div>
      </>
    );
  };

  const StandingsTable = () => {
    return (
      <>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Position</th>
              <th>Name</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            {poolOverview &&
              poolOverview.standing
                .sort((userA, userB) => userA.position - userB.position)
                .filter((user) => user.totalPoints > 0)
                .map((entry: userStanding) => (
                  <tr key={`standings-row-${entry.position}`}>
                    <td>{entry.position}</td>
                    <td>{entry.name}</td>
                    <td>{entry.totalPoints}</td>
                  </tr>
                ))}
          </tbody>
        </Table>
      </>
    );
  };

  const SeasonFinishedSection = () => {
    const standings =
      poolOverview?.standing.sort(
        (userA, userB) => userA.position - userB.position
      ) ?? ([] as userStanding[]);
    const firstFinisher =
      typeof standings[0] === "undefined" ? undefined : standings[0];
    const secondFinisher =
      typeof standings[1] === "undefined" ? undefined : standings[1];
    const thirdFinsher =
      typeof standings[2] === "undefined" ? undefined : standings[2];

    const remainingStandings = standings.slice(3);

    return (
      <StyledSeasonFinshedSection>
        <Row>
          <Col>
            <Podium
              first={firstFinisher}
              second={secondFinisher}
              third={thirdFinsher}
            />
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Name</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {remainingStandings.map((entry: userStanding) => (
                  <tr key={`standings-row-${entry.position}`}>
                    <td>{entry.position}</td>
                    <td>{entry.name}</td>
                    <td>{entry.totalPoints}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <PoolRaceFinishOverview />
          </Col>
        </Row>
      </StyledSeasonFinshedSection>
    );
  };

  interface userPredictionRaceResult {
    finishPosition: number;
    name: string;
    pointsGained: number;
    standingsPosition: number;
  }

  const PoolRaceFinishOverview = () => {
    const mostRecentRace = races
      ?.filter((race) => race.result !== null)
      .sort((a, b) => b.round - a.round)[0];

    const mostRecentRaceResults: userPredictionRaceResult[] | undefined =
      poolOverview?.standing.map((userStanding: userStanding) => {
        const pointsBreakDownForRecentRace = userStanding.pointsBreakdown.find(
          (item) => item.round === mostRecentRace?.round
        );

        return {
          finishPosition: 0,
          name: userStanding.name,
          pointsGained: pointsBreakDownForRecentRace?.pointsGained ?? 0,
          standingsPosition: userStanding.position,
        };
      });

    const orderedUserRaceResults = mostRecentRaceResults?.sort(
      (a, b) => b.pointsGained - a.pointsGained
    );

    return (
      <StyledPoolRaceFinishOverview>
        <h3>Race Prediction Results</h3>
        <Table striped bordered size="sm">
          <thead>
            <tr>
              <th>Finished</th>
              <th>Name</th>
              <th>Points Gained</th>
              <th>Standings</th>
            </tr>
          </thead>
          <tbody>
            {orderedUserRaceResults &&
              orderedUserRaceResults.map(
                (entry: userPredictionRaceResult, index) => (
                  <tr key={`standings-row-${index}`}>
                    <td>{index + 1}</td>
                    <td>{entry.name}</td>
                    <td>
                      {entry.pointsGained >= 1 ? (
                        <span style={{ fontWeight: "bold", color: "#2caa1e" }}>
                          {entry.pointsGained} points
                        </span>
                      ) : (
                        <span>none</span>
                      )}
                    </td>
                    <th>{entry.standingsPosition}</th>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      </StyledPoolRaceFinishOverview>
    );
  };

  const RaceResultRowV2 = (props: {
    key: string;
    prediction: racePredictionResult;
  }) => {
    return (
      <tr>
        <td>{props.prediction.name}</td>
        <td>
          <PredictionCell
            key={`${props.prediction.name}-p1`}
            prediction={props.prediction.pos1}
          />
        </td>
        <td>
          <PredictionCell
            key={`${props.prediction.name}-p2`}
            prediction={props.prediction.pos2}
          />
        </td>
        <td>
          <PredictionCell
            key={`${props.prediction.name}-p3`}
            prediction={props.prediction.pos3}
          />
        </td>
        <td>
          <PredictionCell
            key={`${props.prediction.name}-p4`}
            prediction={props.prediction.pos4}
          />
        </td>
        <td>
          <PredictionCell
            key={`${props.prediction.name}-p5`}
            prediction={props.prediction.pos5}
          />
        </td>
        <td>
          <PredictionCell
            key={`${props.prediction.name}-fastest`}
            prediction={props.prediction.fastestLap}
          />
        </td>
        <td>{props.prediction.totalPoints}</td>
      </tr>
    );
  };

  const PredictionCell = (props: {
    key: string;
    prediction: predictionResult;
  }) => {
    return (
      <StyledPredictionForPosition verdict={props.prediction.verdict}>
        <DriverDisplay driverNumber={props.prediction.predicted} />{" "}
        <StyledPredictionPointsPill verdict={props.prediction.verdict}>
          {props.prediction.points}
        </StyledPredictionPointsPill>
      </StyledPredictionForPosition>
    );
  };

  const StyledPredictionForPosition = styled.span<{
    verdict: PredictionVerdict;
  }>`
    font-weight: bold;
    color: ${(p) => {
      if (p.verdict === PredictionVerdict.Exact) return "#990099";
      else if (p.verdict === PredictionVerdict.Near) return "#2caa1e";
      else if (p.verdict === PredictionVerdict.Wrong) return "#777";
      else return "#ff0000"; // no mapping available
    }};
    border-bottom: solid 2px;
    border-bottom-color: ${(p) => {
      if (p.verdict === PredictionVerdict.Exact) return "#990099";
      else if (p.verdict === PredictionVerdict.Near) return "#2caa1e";
      else if (p.verdict === PredictionVerdict.Wrong) return "#777";
      else return "#ff0000"; // no mapping available
    }};
    padding: 2px 4px;
  `;

  const StyledPredictionPointsPill = styled.span<{
    verdict: PredictionVerdict;
  }>`
    background-color: ${(p) => {
      if (p.verdict === PredictionVerdict.Exact) return "#990099";
      else if (p.verdict === PredictionVerdict.Near) return "#2caa1e";
      else if (p.verdict === PredictionVerdict.Wrong) return "#777";
      else return "#ff0000"; // no mapping available
    }};
    padding: 0px 4px;
    border-radius: 50%;
    color: #fff;
    font-size: 0.7em;
    width: 30px:
    height: 18px
    line-height: 18px;
    text-align: center;
  `;

  const RaceResultTableV2 = (props: {
    key: number;
    result: raceResult;
    predictions: racePredictionResult[];
  }) => {
    return (
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th>User</th>
            <th>
              1st <DriverDisplay driverNumber={props.result.pos1} />
            </th>
            <th>
              2nd <DriverDisplay driverNumber={props.result.pos2} />
            </th>
            <th>
              3rd <DriverDisplay driverNumber={props.result.pos3} />
            </th>
            <th>
              4th <DriverDisplay driverNumber={props.result.pos4} />
            </th>
            <th>
              5th <DriverDisplay driverNumber={props.result.pos5} />
            </th>
            <th>
              Fastest <DriverDisplay driverNumber={props.result.fastestLap} />
            </th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {props.predictions
            .sort((a, b) => b.totalPoints - a.totalPoints)
            .map((prediction: racePredictionResult, index: number) => {
              return (
                <RaceResultRowV2 key={`${index}`} prediction={prediction} />
              );
            })}
        </tbody>
      </Table>
    );
  };

  const RaceResultSection = (props: {
    key: number;
    raceResult: raceResultOverview;
  }) => {
    return (
      <div style={{ marginTop: 40 }}>
        <h2>
          <Flag code={props.raceResult.countryCode} height="28" />{" "}
          {props.raceResult.raceName}
        </h2>
        {props.raceResult.result != null &&
          props.raceResult.predictions != null && (
            <RaceResultTableV2
              key={props.raceResult.round}
              result={props.raceResult.result}
              predictions={props.raceResult.predictions}
            />
          )}
      </div>
    );
  };

  const DriverDisplay = (props: { driverNumber: number | null }) => {
    let name: string = "None";

    if (props.driverNumber !== null) {
      const driver = drivers.find(
        (x: Driver) => x.number === props.driverNumber
      );

      if (driver === undefined) {
        name = "Invalid";
      } else {
        name = driver.abbreviation!;
      }
    }
    return <span>{name}</span>;
  };

  const RaceWeekendBanner = () => {
    return (
      <>
        <Row>
          <Col>
            <Card
              style={{
                marginTop: 40,
                marginBottom: 40,
                backgroundImage:
                  "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url('/hungary.jpg')",
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "#FFF",
              }}
            >
              <Card.Header>
                <h3>
                  <Flag code={raceWeekend?.countryCode} height="30" />{" "}
                  {raceWeekend?.name}
                </h3>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                {raceWeekend?.sprintQualifying && (
                  <CountdownPill
                    date={raceWeekend?.sprintQualifying.start}
                    label="until sprint start"
                    variant="light"
                    completedText="Sprint has started"
                    completedVariant="danger"
                  />
                )}
                <CountdownPill
                  date={raceWeekend?.raceStart!}
                  label="until race start"
                  variant="danger"
                  completedText="Race has started"
                  completedVariant="danger"
                />
              </Card.Body>
              <Card.Footer style={{ textAlign: "center" }}>
                {raceWeekend?.sprintQualifying && (
                  <Link
                    to={`/SprintQualifyingPrediction/${poolSlug}/${raceWeekend?.round}`}
                    style={{ marginRight: 80 }}
                  >
                    <Button variant="primary">Predict Sprint Result</Button>
                  </Link>
                )}
                <Link to={`/RacePrediction/${poolSlug}/${raceWeekend?.round}`}>
                  <Button variant="danger">Predict Race Result</Button>
                </Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <p>Predictions made by:</p>
            {raceParticipants.map((user) => {
              return (
                <Badge
                  key={`participant-${user}`}
                  pill
                  bg="danger"
                  style={{ margin: "0 5px" }}
                >
                  {user}
                </Badge>
              );
            })}
          </Col>
        </Row>
      </>
    );
  };

  const NotFoundMessage = () => {
    return (
      <Row className="justify-content-md-center">
        <Col lg={4} style={{ margin: "40px" }}>
          <h2>Pool not found</h2>
          <p>
            No pool with name "{poolSlug}" was found.{" "}
            <Link to="/">go back to home</Link>
          </p>
        </Col>
      </Row>
    );
  };

  const ErrorMessage = () => {
    return (
      <Row className="justify-content-md-center">
        <Col lg={4} style={{ margin: "40px" }}>
          <h2>Oops</h2>
          <p>{errorMessage}</p>
          <p>
            <Link to="/">go back to home</Link>
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {pageState === PageState.Loading ? (
        <PageLoadingPlaceholder />
      ) : (
        <>
          <NavBar />
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col>
                {pageState === PageState.Done && <OverviewPage />}
                {pageState === PageState.NotFound && <NotFoundMessage />}
                {pageState === PageState.Error && <ErrorMessage />}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

const StyledSeasonFinshedSection = styled.div`
  margin: 20px auto;
  max-width: 600px;
`;

const StyledPoolRaceFinishOverview = styled.div`
  margin-top: 40px;
`;
