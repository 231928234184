import { BrowserRouter as Router, Route } from "react-router-dom";
import { AdminPage } from "./Pages/AdminPage";
import { EditRacePage } from "./Pages/EditRacePage";
import { PoolOverviewPage } from "./Pages/PoolOverviewPage";
import { RacePredictionPage } from "./Pages/RacePredictionPage";
import { SprintQualifyingPredictionPage } from "./Pages/SprintQualifyingPredictionPage";
import { useAuth } from "oidc-react";
import { Home } from "./Pages/Home";
import { PointPreviewPage } from "./Pages/PointPreviewPage";
import { AdminDivisionPage } from "./Pages/AdminDivisionPage";
import { OldPoolOverviewPage } from "./Pages/OldPoolOverviewPage";
import { AdminPoolPage } from "./Pages/AdminPoolPage";
import { Join } from "./Pages/Join";
import { PoolPage } from "./Pages/PoolPage";
import { PageLoadingPlaceholder2 } from "./Components/PageLoadingPlaceholder2";

function App() {
  const auth = useAuth();

  if (auth.userData === null) {
    return <PageLoadingPlaceholder2 />;
  }

  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route
        exact
        path="/RacePrediction/:poolSlug/:round"
        component={RacePredictionPage}
      />
      <Route
        exact
        path="/SprintQualifyingPrediction/:poolSlug/:round"
        component={SprintQualifyingPredictionPage}
      />
      <Route exact path="/overview" component={Home} />
      <Route
        exact
        path="/old-overview/:poolSlug"
        component={PoolOverviewPage}
      />
      <Route exact path="/overview/:poolSlug" component={PoolPage} />
      <Route exact path="/pool/:poolSlug" component={PoolPage} />
      <Route exact path="/join/:poolSlug" component={Join} />
      <Route
        exact
        path="/oldOverview/:poolSlug"
        component={OldPoolOverviewPage}
      />
      <Route exact path="/Admin" component={AdminPage} />
      <Route
        exact
        path="/Admin/Division/:divisionSlug"
        component={AdminDivisionPage}
      />
      <Route
        exact
        path="/Admin/Division/:divisionSlug/EditRace/:round"
        component={EditRacePage}
      />
      <Route exact path="/Admin/Pool/:poolSlug" component={AdminPoolPage} />
      <Route
        exact
        path="/PointPreview/:poolSlug"
        component={PointPreviewPage}
      />
    </Router>
  );
}

export default App;
