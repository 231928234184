import { useEffect, useState } from "react";
import { race } from "../Models";

export const useNextRace = (races: race[] | undefined) => {
  const [nextRace, setNextRace] = useState<race>();

  useEffect(() => {
    if (races !== undefined) {
      const now = Date.now();
      const racesYetToStart = races.filter((race: race) => {
        const diff = new Date(race.raceStart).getTime() - now;
        return diff > 259200000;
      });
      const racesSoonestToLast = racesYetToStart.sort(
        (a, b) =>
          new Date(a.raceStart).getTime() - new Date(b.raceStart).getTime()
      );
      if (racesSoonestToLast.length > 0) {
        setNextRace(racesSoonestToLast.at(0));
      }
    }
  }, [races]);

  return [nextRace] as const;
};
