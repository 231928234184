import React from "react";
import { Form, Row, Col } from "react-bootstrap";

interface Props {
    label: string;
    value: number | undefined;
    onChange: (value: number) => void;
};

class EditableNumberField extends React.Component<Props> {
    render() {
        return (
            <Form.Group as={Row}>
                <Form.Label column sm={3} style={{fontWeight: 'bold'}}>
                    {this.props.label}
                </Form.Label>
                <Col sm={9}>
                    <Form.Control
                        type="number"
                        value={this.props.value}
                        onChange={(event) => this.props.onChange(parseInt(event.target.value) ?? 0)}
                    />
                </Col>
            </Form.Group>
        );
    }
}

export default EditableNumberField;