import { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Division, race, userWithPools, pool } from "../Models";
import { NavBar } from "../Components/NavBar";
import { Service } from "../Service";

interface IResponseMessage {
  readonly isSuccess: boolean;
  readonly message: string;
}

export const AdminPage = () => {
  const [divisions, setDivisions] = useState<Division[]>();
  const [users, setUsers] = useState<userWithPools[]>();
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<userWithPools>();
  const [authorized, setAuthorized] = useState<boolean>(true);
  const [userSaveResponseMessage, setUserSaveResponseMessage] =
    useState<IResponseMessage>();

  useEffect(() => {
    Service.getDivisions().then((response: Response) => {
      if (isUnautorized(response)) {
        setAuthorized(false);
      } else if (response.status === 200) {
        response.json().then((divisionsResponse: Division[]) => {
          setDivisions(divisionsResponse);
        });
      }
    });
    loadUserData();
    return () => {};
  }, []);

  useEffect(() => {
    if (selectedUser !== undefined) {
      loadSelectedUser(selectedUser.userId);
    }
  }, [users]);

  const isUnautorized = (response: Response) => {
    return response.status === 401 || response.status === 403;
  };

  const handleClose = () => {
    setShowUserModal(false);
    setSelectedUser(undefined);
    setUserSaveResponseMessage(undefined);
  };

  const handleSaveUserChanges = () => {
    if (selectedUser !== undefined) {
      Service.updateUser(selectedUser).then((response: Response) => {
        response.json().then((body) => {
          setUserSaveResponseMessage({
            isSuccess: response.status === 200,
            message: body.message,
          });
        });
        if (response.status === 200) {
          loadUserData();
        }
      });
    }
  };

  const loadUserData = () => {
    Service.getAllUsers().then((response: Response) => {
      if (isUnautorized(response)) {
        setAuthorized(false);
      } else if (response.status === 200) {
        response.json().then((usersResponse: userWithPools[]) => {
          setUsers(usersResponse);
        });
      }
    });
  };

  const loadSelectedUser = (userId: string) => {
    const user = users?.find((x) => x.userId === userId);
    setSelectedUser(user);
    console.log("selected user loaded");
  };

  const AdminPageContent = () => {
    return (
      <>
        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <h3>Divisions</h3>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button variant="success" onClick={() => AddDivisionLink()}>
              Add Division
            </Button>
          </Col>
        </Row>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Slug</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {divisions &&
              divisions.map((division: Division) => {
                return (
                  <DivisionRow
                    key={`key-${division.divisionId}`}
                    division={division}
                  />
                );
              })}
          </tbody>
        </Table>

        <br />

        <Row style={{ marginBottom: "20px" }}>
          <Col>
            <h3>Users</h3>
          </Col>
          <Col style={{ textAlign: "right" }}>
            user count: <Badge bg="primary">{users?.length}</Badge>
          </Col>
        </Row>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
              <th>Pools</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user: userWithPools) => {
                return <UserRow key={`key-${user.userId}`} user={user} />;
              })}
          </tbody>
        </Table>
      </>
    );
  };

  const DivisionRow = (props: { key: string; division: Division }) => {
    return (
      <tr>
        <td>{props.division.slug}</td>
        <td>
          {props.division.name}{" "}
          {props.division.isSeasonFinished && (
            <Badge bg="secondary">Season Finished</Badge>
          )}
        </td>
        <td style={{ textAlign: "right" }}>
          <Button
            variant="outline-primary"
            onClick={() => ViewDivisionLink(props.division.slug)}
          >
            View
          </Button>
        </td>
      </tr>
    );
  };

  const UserRow = (props: { key: string; user: userWithPools }) => {
    return (
      <tr>
        <td>
          {props.user.name}
          {props.user.isAdmin && (
            <Badge bg="success" style={{ marginLeft: "4px" }}>
              Admin
            </Badge>
          )}
        </td>
        <td>
          {props.user.pools &&
            props.user.pools.map((pool: pool) => {
              return (
                <Badge
                  bg={pool.isSeasonFinished ? "secondary" : "primary"}
                  style={{ marginLeft: "4px" }}
                >
                  {pool.name}
                </Badge>
              );
            })}
        </td>
        <td style={{ textAlign: "right" }}>
          <Button
            variant="outline-primary"
            onClick={() => ViewUserLink(props.user.userId)}
          >
            View
          </Button>
        </td>
      </tr>
    );
  };

  const ViewDivisionLink = (slug: string) => {
    window.location.href = `/Admin/Division/${slug}`;
  };

  const ViewUserLink = (userId: string) => {
    loadSelectedUser(userId);
    setShowUserModal(true);
  };

  const AddDivisionLink = () => {
    window.location.href = "/CreateDivision";
  };

  const UnAuthorized = () => {
    return (
      <>
        <h1>Unauthorized</h1>
        <p>
          <Link to="/overview">go back to home</Link>
        </p>
      </>
    );
  };

  const handleSelectedUserChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setSelectedUser({ ...selectedUser, [name]: value } as userWithPools);
  };

  return (
    <>
      <NavBar />
      <Container fluid="md" style={{ marginTop: "50px" }}>
        <Row className="justify-content-md-center">
          <Col lg={7}>
            {authorized ? <AdminPageContent /> : <UnAuthorized />}
          </Col>
        </Row>
      </Container>

      <Modal
        show={showUserModal && selectedUser !== undefined}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="input"
                value={selectedUser?.name}
                onChange={handleSelectedUserChange}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={selectedUser?.email}
                onChange={handleSelectedUserChange}
                name="email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Given name</Form.Label>
              <Form.Control
                type="input"
                value={selectedUser?.givenName}
                onChange={handleSelectedUserChange}
                name="givenName"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Family name</Form.Label>
              <Form.Control
                type="input"
                value={selectedUser?.familyName}
                onChange={handleSelectedUserChange}
                name="familyName"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                checked={selectedUser?.isAdmin}
                label="Admin"
                disabled
              />
            </Form.Group>
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Pool</th>
                  <th>Pool slug</th>
                  <th>Division slug</th>
                </tr>
              </thead>
              <tbody>
                {selectedUser?.pools &&
                  selectedUser?.pools.map((pool: pool) => {
                    return (
                      <tr>
                        <td>{pool.name}</td>
                        <td>{pool.slug}</td>
                        <td>{pool.divisionSlug}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <Form.Group className="mb-3">
              <Form.Label>UserID</Form.Label>
              <Form.Control
                type="input"
                value={selectedUser?.userId}
                disabled
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Sub</Form.Label>
              <Form.Control type="input" value={selectedUser?.sub} disabled />
            </Form.Group>
          </Form>
          {userSaveResponseMessage && (
            <Alert
              variant={userSaveResponseMessage.isSuccess ? "success" : "danger"}
            >
              {userSaveResponseMessage.message}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveUserChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
