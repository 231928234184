import { Container, Navbar, Spinner } from "react-bootstrap"
import styled from "styled-components";

export const PageLoadingPlaceholder = () => {
 
    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand>F1 Pool</Navbar.Brand>
                </Container>
            </Navbar>
            <StyledLoadingWrapper>
                <Spinner animation="border" variant="danger" />
            </StyledLoadingWrapper>
        </div>)
}

const StyledLoadingWrapper = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
`;