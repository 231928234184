import {
  Alert,
  Button,
  ButtonGroup,
  Dropdown,
  Modal,
  Spinner,
} from "react-bootstrap";
import Flag from "react-world-flags";
import styled from "styled-components";
import {
  Driver,
  PredictionPosition,
  pool,
  race,
  racePrediction,
  racePrediction2,
  userWithPools,
} from "../Models";
import { StopwatchFill } from "react-bootstrap-icons";
import { DriverSelection } from "./DriverSelection";
import { useEffect, useState } from "react";
import { DriversKeyboard, SelectionRequest } from "./DriversKeyboard";
import { Service } from "../Service";
import { StartingGrid } from "./StartingGrid";

export interface IControlPredictionEdit {
  predictionSource: racePrediction2 | undefined;
}

enum State {
  Ready,
  Loading,
}

export interface IProps {
  show: boolean;
  user?: userWithPools;
  onHide: () => void;
  race: race | undefined;
  control: IControlPredictionEdit | undefined;
  drivers: Driver[] | undefined;
  pool: pool | undefined;
  onPredictionChanged: (updatedPrediction: racePrediction) => void;
}

export const RacePredictionModal = ({
  race,
  control,
  drivers,
  pool,
  user,
  show,
  onHide,
  onPredictionChanged,
}: IProps) => {
  const [prediction, setPrediction] = useState<racePrediction2>();
  const [selectionRequest, setSelectionRequest] = useState<SelectionRequest>();
  const [canSave, setCanSave] = useState<boolean>(false);
  const [state, setState] = useState<State>(State.Ready);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [activePools, setActivePools] = useState<pool[]>([]);

  useEffect(() => {
    console.log(control);
    if (control !== undefined) {
      const predictionCopy =
        control.predictionSource !== undefined
          ? { ...control.predictionSource }
          : undefined;
      setPrediction(predictionCopy);
    }
  }, [control]);

  useEffect(() => {
    let valid = false;
    if (prediction !== undefined) {
      const positionValues: (number | undefined)[] = [
        prediction.pos1,
        prediction.pos2,
        prediction.pos3,
        prediction.pos4,
        prediction.pos5,
      ];
      const selectedDrivers = positionValues.filter(
        (driverNumber) => driverNumber !== undefined
      );
      const allFieldsFilledOut =
        selectedDrivers.length === 5 && prediction.fastestLap !== undefined;
      console.log(allFieldsFilledOut);
      const hasDuplicates: boolean = selectedDrivers.some(
        (num, index) =>
          num !== undefined && selectedDrivers.indexOf(num) !== index
      );
      console.log(hasDuplicates);
      if (allFieldsFilledOut && !hasDuplicates) valid = true;
    }
    setCanSave(valid);
  }, [prediction]);

  useEffect(() => {
    if (user !== undefined && race !== undefined) {
      setActivePools(
        user.pools.filter(
          (p) => p.divisionSlug === race.division.slug && !p.isSeasonFinished
        )
      );
    }
  }, [user, race]);

  const handleHide = () => {
    if (state === State.Ready) onHide();
  };

  const initiateSelection = (selectionRequest: SelectionRequest) => {
    setSelectionRequest(selectionRequest);
  };

  const onDriverSelected = (
    selectedDriver: Driver | undefined,
    position: PredictionPosition
  ) => {
    setPrediction((prevPrediction) => {
      const updatedPrediction: racePrediction2 = {
        ...prevPrediction,
      } as racePrediction2;

      if (selectedDriver !== undefined) {
        if (position !== PredictionPosition.fastestLap) {
          for (const key in updatedPrediction) {
            if (
              Object.prototype.hasOwnProperty.call(updatedPrediction, key) &&
              key !== PredictionPosition.fastestLap
            ) {
              const currentPosition = key as keyof racePrediction2;
              if (
                updatedPrediction[currentPosition] === selectedDriver.number &&
                currentPosition !== position
              ) {
                updatedPrediction[currentPosition] = undefined;
              }
            }
          }
        }
      }

      updatedPrediction[position] = selectedDriver?.number;

      return updatedPrediction;
    });
  };

  const savePrediction = () => {
    if (
      prediction !== undefined &&
      pool !== undefined &&
      race !== undefined &&
      canSave
    ) {
      setErrorMessage(undefined);
      setState(State.Loading);
      const predictionToSave: racePrediction = {
        round: race.round,
        pos1: prediction.pos1,
        pos2: prediction.pos2,
        pos3: prediction.pos3,
        pos4: prediction.pos4,
        pos5: prediction.pos5,
        fastestLap: prediction.fastestLap,
      } as racePrediction;
      Service.updateRacePrediction(
        pool.slug,
        race.round,
        predictionToSave
      ).then((response: Response) => {
        response.json().then((body) => {
          setState(State.Ready);
          if (response.status === 200 && body.isSuccess) {
            onPredictionChanged(predictionToSave);
            handleHide();
          } else {
            setErrorMessage(body.message);
          }
        });
      });
    }
  };

  const savePredictionForAllPools = () => {
    if (
      prediction !== undefined &&
      pool !== undefined &&
      race !== undefined &&
      canSave
    ) {
      setErrorMessage(undefined);
      setState(State.Loading);
      const predictionToSave: racePrediction = {
        round: race.round,
        pos1: prediction.pos1,
        pos2: prediction.pos2,
        pos3: prediction.pos3,
        pos4: prediction.pos4,
        pos5: prediction.pos5,
        fastestLap: prediction.fastestLap,
      } as racePrediction;
      Service.updateRacePredictionForAllPools(
        race.division.slug,
        race.round,
        predictionToSave
      ).then((response: Response) => {
        response.json().then((body) => {
          setState(State.Ready);
          if (response.status === 200 && body.isSuccess) {
            onPredictionChanged(predictionToSave);
            handleHide();
          } else {
            setErrorMessage(body.message);
          }
        });
      });
    }
  };

  const getDriverByNumber = (
    driverNumber: number | undefined | null
  ): Driver | undefined => {
    return driverNumber !== undefined || driverNumber !== null
      ? drivers?.find((driver: Driver) => driver.number === driverNumber)
      : undefined;
  };

  const StyledModal = styled(Modal)`
    font-family: "Formula1";
    background-color: #555 !important;
  `;

  const StyledModalBody = styled(Modal.Body)`
    background-color: #eee;
    padding: 0;
  `;

  const Container = styled.div`
    display: grid;
    grid-template-columns: [prediction] auto [grid] 105px;
    gap: 5px;
  `;

  const PredictionContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(6, 60px);
    gap: 10px;
    margin: 10px 0;
  `;

  const PredictionRow = styled.div`
    display: grid;
    grid-template-columns: [position] 25px [driverSelection] auto;
    gap: 0;
  `;

  const Position = styled.div`
    display: grid;
    font-weight: bold;
    font-size: 16px;
    justify-content: center;
    align-content: center;
  `;

  const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  `;

  const StyledHeader = styled.div`
    margin: 20px;
    text-align: center;
  `;

  const StyledGridNotVisibleMessage = styled.p`
    font-size: 12px;
    color: #999;
    font-style: italic;
  `;

  const PositionLabel = (props: { position: PredictionPosition }) => {
    switch (props.position) {
      case PredictionPosition.pos1:
        return <Position>1</Position>;
      case PredictionPosition.pos2:
        return <Position>2</Position>;
      case PredictionPosition.pos3:
        return <Position>3</Position>;
      case PredictionPosition.pos4:
        return <Position>4</Position>;
      case PredictionPosition.pos5:
        return <Position>5</Position>;
      case PredictionPosition.fastestLap:
        return (
          <Position>
            <StopwatchFill color="#990099" />
          </Position>
        );
      default:
        return <></>;
    }
  };

  const CardPredictionRow = (props: {
    position: PredictionPosition;
    driverNumber: number | undefined;
  }) => {
    const driver = getDriverByNumber(props.driverNumber);
    return (
      <PredictionRow>
        <PositionLabel position={props.position} />
        <DriverSelection
          position={props.position}
          driver={driver}
          onInitiateSelect={initiateSelection}
          disabled={state === State.Loading}
        />
      </PredictionRow>
    );
  };

  if (race === undefined || drivers === undefined) return <></>;

  return (
    <>
      <StyledModal
        show={show}
        fullscreen={"sm-down"}
        onHide={handleHide}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Flag code={race.countryCode} height={18} />
            {"  "}
            {race.name}
          </Modal.Title>
        </Modal.Header>
        <StyledModalBody>
          <StyledHeader>
            {race.startingGrid === null && (
              <StyledGridNotVisibleMessage>
                Starting grid available after qualifying session
              </StyledGridNotVisibleMessage>
            )}
          </StyledHeader>
          <Container>
            <PredictionContainer>
              <CardPredictionRow
                position={PredictionPosition.pos1}
                driverNumber={prediction?.pos1}
              />
              <CardPredictionRow
                position={PredictionPosition.pos2}
                driverNumber={prediction?.pos2}
              />
              <CardPredictionRow
                position={PredictionPosition.pos3}
                driverNumber={prediction?.pos3}
              />
              <CardPredictionRow
                position={PredictionPosition.pos4}
                driverNumber={prediction?.pos4}
              />
              <CardPredictionRow
                position={PredictionPosition.pos5}
                driverNumber={prediction?.pos5}
              />
              <CardPredictionRow
                position={PredictionPosition.fastestLap}
                driverNumber={prediction?.fastestLap}
              />
            </PredictionContainer>
            <StartingGrid
              getDriverByNumber={getDriverByNumber}
              startingGrid={race.startingGrid}
            />
          </Container>
          <ButtonContainer>
            {state === State.Ready && activePools.length > 1 && (
              <Dropdown as={ButtonGroup} drop="up-centered">
                <Button
                  variant="success"
                  onClick={savePrediction}
                  disabled={!canSave}
                >
                  Save
                </Button>

                <Dropdown.Toggle
                  split
                  variant="success"
                  id="dropdown-split-basic"
                  disabled={!canSave}
                />

                <Dropdown.Menu>
                  <Dropdown.Item onClick={savePredictionForAllPools}>
                    Save for all pools
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {activePools.map((pool: pool, index) => {
                    return (
                      <Dropdown.Header key={`${index}-item`}>
                        {pool.name}
                      </Dropdown.Header>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {state === State.Ready && activePools.length <= 1 && (
              <Button
                onClick={savePrediction}
                variant="success"
                disabled={!canSave}
              >
                Save
              </Button>
            )}
            {state === State.Loading && (
              <Button variant="success" disabled={true}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                Loading
              </Button>
            )}
          </ButtonContainer>

          {errorMessage !== undefined && (
            <Alert variant="danger">{errorMessage}</Alert>
          )}
        </StyledModalBody>
      </StyledModal>
      <DriversKeyboard
        drivers={drivers}
        selectionRequest={selectionRequest}
        onDriverSelected={onDriverSelected}
      />
    </>
  );
};
