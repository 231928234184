import styled from "styled-components";
import { Driver, PredictionPosition } from "../Models";
import { SelectionRequest } from "./DriversKeyboard";

export interface IProps {
  position: PredictionPosition;
  driver: Driver | undefined;
  onInitiateSelect: (selectionRequest: SelectionRequest) => void;
  disabled: boolean;
}

export const DriverSelection = ({
  driver,
  onInitiateSelect,
  position,
  disabled,
}: IProps) => {
  const DriverSelectionContainer = styled.div<{
    teamColor: string;
    disabled: boolean;
  }>`
    display: grid;
    grid-area: "driverSelection";
    grid-template-columns: 1fr auto;
    grid-template-rows: 60px;
    background-color: #fff;
    border-bottom: 3px solid;
    border-color: ${(props) => props.teamColor};
    border-radius: 8px;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: 0 5px;
    ${(props) =>
      props.disabled
        ? `
      opacity: 0.5;
    `
        : `
      cursor: pointer;
    `}
  `;

  const DriverName = styled.div`
    text-align: left;
    position: absolute;
    left: 10px;
    line-height: 1;
    color: #444;
  `;

  const Firstname = styled.span`
    font-size: 12px;
  `;

  const Lastname = styled.span`
    font-size: 18px;
    font-weight: bold;
  `;

  const DriverImage = styled.img`
    justify-self: end;
    max-width: 100%;
    max-height: 100%;
  `;

  const onClick = () => {
    if (!disabled)
      onInitiateSelect({
        position: position,
        selectedDriver: driver?.number,
      });
  };

  if (driver === undefined)
    return (
      <DriverSelectionContainer
        onClick={onClick}
        teamColor="#000"
        disabled={disabled}
      >
        <DriverName>Select a driver</DriverName>
      </DriverSelectionContainer>
    );

  return (
    <DriverSelectionContainer
      onClick={onClick}
      teamColor={driver.team.color}
      disabled={disabled}
    >
      <DriverName>
        <Firstname>{driver?.firstname}</Firstname>
        <br />
        <Lastname>{driver?.lastname}</Lastname>
      </DriverName>
      <DriverImage
        src={`/drivers/${driver.abbreviation.toLowerCase()}.png`}
        alt={driver.abbreviation}
      />
    </DriverSelectionContainer>
  );
};
